<template>
  <div class="container">
    <div class="contact-container">
      <div class="contact-component">
        <ContactComponent />
      </div>
      <div class="send-message">
        <SendMessage />
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from '@/components/Footer/Footer'
import ContactComponent from '@/components/ContactComponent'
import SendMessage from '@/components/SendMessage'
import { useMeta } from 'vue-meta'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
export default {
  name: 'Contact',
  components: {
    Footer, ContactComponent, SendMessage
  },
  setup () {
    const store = useStore()

    const { t } = useI18n({
      messages: {
        en: {
          title: 'Contact',
          description: 'Would you like to see our products in action? Feel free to request a demo using the contact form. We will contact you as soon as possible.'
        },
        nl: {
          title: 'Contact',
          description: 'Wilt u onze producten in actie zien? Vraag gerust een demo aan via het contactformulier. Wij zulllen zo spoedig mogelijk contact met u opnemen.'
        }
      }
    })
    useMeta({
      title: t('title', {}, { locale: store.state.lang }),
      description: t('description', {}, { locale: store.state.lang })
    })
  },
  mounted () {
    this.$store.commit('updateActivePage', 'Contact')
    // If previous route was not pricing, make textarea empty
    const previousRoute = this.$router.options.history.state.back.split('/')
    if (previousRoute[2] !== 'pricing') {
      this.$store.commit('update_message', '')
    }
    // document.title = this.$t('General.Contact', {}, { locale: this.$store.state.lang })
  }
}

</script>

<style lang="scss" scoped>
.container {
  position: relative;
  min-height: calc(100vh - 75px - 309px); // calculation to make the page full screen
}
  .contact-container {
    color: #606060;
    display: grid;
    grid-template-areas: '. contact send-message .';
    grid-template-columns: 1.8fr 3.2fr 3.2fr 1.8fr;
    grid-template-rows: auto;
    padding-bottom: 2em;
    .contact-component {
        grid-area: contact;
    }
    .send-message {
        grid-area: send-message;
    }
  }
  @media screen and (min-width: 2300px) {
    .contact-container {
      max-width: 2300px;
      margin: 0 auto;
    }
  }
  @media screen and (max-width: 1500px) {
    .contact-container {
      grid-template-columns: 0.8fr 4.2fr 4.2fr 0.8fr;
    }
  }
  @media screen and (max-width: 1066px) {
    .contact-container {
      grid-template-columns: 0.3fr 4.7fr 4.7fr 0.3fr;
    }
  }
  @media screen and (max-width: 740px) {
    .contact-container {
         grid-template-columns: 0.3fr 9.4fr 0.3fr;
         grid-template-areas: '. contact .'
                              '. send-message .';
    }
  }
</style>
