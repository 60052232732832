<template>
  <div>
    <div>
      <div v-if="variation==='text'">
        {{ $t(`General.${text}`, {}, { locale: $store.state.lang }) }}
      </div>
      <div
        v-if="variation==='clickable-list'"
      >
        <ul
          v-for="item in list"
          :key="item"
        >
          <li :style="[variation=== 'clickable-list' ? {'cursor': 'pointer'} : '']">
            <router-link
              v-if="variation=== 'clickable-list'"
              :to="`/${$store.state.lang}/${item}`"
              class="link"
            >
              {{ $t(`General.${item}`, {}, { locale: $store.state.lang }) }}
            </router-link>
          </li>
        </ul>
      </div>
      <div v-if="variation==='list'">
        {{ $t(`General.CompeteStreetAndNumber`, {}, { locale: $store.state.lang }) }}
        <br>
        {{ $t(`General.CompetePostalCode`, {}, { locale: $store.state.lang }) }}
        <br>
        {{ $t(`General.CompeteCity`, {}, { locale: $store.state.lang }) }}
        <br>
        <a href="tel:+31102382045">{{ $t(`General.CompetePhoneNumber`, {}, { locale: $store.state.lang }) }}</a>
        <br>
        <a href = "mailto: sales@compete.nl">{{ $t(`General.CompeteEmail`, {}, { locale: $store.state.lang }) }} </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardFooter',
  props: {
    title: {
      type: String,
      default: 'title'
    },
    text: {
      type: String,
      default: 'ContentText'
    },
    list: {
      type: Array,
      default: () => []
    },
    variation: {
      type: String,
      default: 'text',
      validator: value => {
        return value.match(/(text|list|clickable-list)/)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
ul {
    list-style: none;
    margin:0;
    padding:0;
    padding-bottom:0.4em;
    &.clickable-list {
        cursor: pointer;
    }
}
.link, a {
  text-decoration: none;
  color: inherit;
}
</style>
