<template>
  <div>
    <h3>
      {{ text }}<br>
    </h3>
  </div>
</template>

<script>
export default {
  name: 'SubHeading',
  props: {
    text: {
      type: String,
      default: 'Heading'
    }
  }
}
</script>

<style lang="scss" scoped>
h3 {
  margin-bottom: 0em;
  font-weight: 600;
}
</style>
