<template>
  <div class="leave-container">
    <MainSection
      :title="$t('Timesick.ProductName', {}, { locale: $store.state.lang })"
      :subheader="$t('Timesick.SubHeaderText', {}, { locale: $store.state.lang })"
      :btn-text="$t('General.ContactUsBtn', {}, { locale: $store.state.lang })"
      :sub-heading="true"
      image-url="Main-Cropped.webp"
      :no-padding-img="true"
      alt-tag-text="LapTopSoftwareOverviewAltTag"
      @contact-btn-clicked="contactBtnClicked()"
    >
      <template #main-section>
        <p>
          {{ $t('Timesick.MainSectionText', {}, { locale: $store.state.lang }) }}
        </p>
      </template>
    </MainSection>
    <AdvantagesSection
      :primary-color="'#606060'"
      :title="$t('Timesick.Advantages', {}, { locale: $store.state.lang })"
      :background-color="'white'"
      image-url="Manager.webp"
      alt-tag-text="ManLookingAtWatchAltTag"
      :try-buy-buttons="true"
      @contact-btn-clicked="contactBtnClicked()"
    >
      <template #main-section>
        <p>
          {{ $t('Timesick.MainSectionAdvantages', {}, { locale: $store.state.lang }) }}
        </p>
      </template>
    </AdvantagesSection>
    <MainSection
      :show-buttons="false"
      :primary-color="'#606060'"
      :title="$t('Timesick.MainSectionTitlePart2', {}, { locale: $store.state.lang })"
      :background-color="'white'"
      image-url="Leave-Desktop-Cropped.webp"
      alt-tag-text="LaptopCompeteSoftWareAltTag"
    >
      <template #main-section>
        <p>
          {{ $t('Timesick.MainSectionTextPart2', {}, { locale: $store.state.lang }) }}
        </p>
      </template>
    </MainSection>
    <MainSection
      :reversed="true"
      :show-buttons="true"
      :primary-color="'#606060'"
      :background-color="'white'"
      :title="$t('General.MobileAppSectionTitle', {}, { locale: $store.state.lang })"
      image-url="Leave-Mobile-Cropped.webp"
      alt-tag-text="MobileLeaveSoftwareAltTag"
      :margin="false"
      @contact-btn-clicked="contactBtnClicked()"
    >
      <template #main-section>
        <p>
          {{ $t('Timesick.MobileAppSectionText', {}, { locale: $store.state.lang }) }}
        </p>
      </template>
    </MainSection>
    <MainSection
      :show-buttons="false"
      :primary-color="'#606060'"
      :title="$t('General.DifferentLanguages', {}, { locale: $store.state.lang })"
      :margin="false"
      image-url="Languages-Cropped.webp"
      alt-tag-text="MobileLanguagesAltTag"

    >
      <template #main-section>
        <p>
          {{ $t('General.DifferentLanguageText', {}, { locale: $store.state.lang }) }}
        </p>
      </template>
    </MainSection>
    <MainSection
      :reversed="true"
      :show-buttons="false"
      :try-buy-buttons="true"
      :double-buttons="true"
      :primary-color="'#606060'"
      :margin="false"
      :title="$t('General.GiveUpOvertime', {}, { locale: $store.state.lang })"
      image-url="Overtime.webp"
      alt-tag-text="LaptopOverTimeClockAltTag"
    >
      <template #main-section>
        <p>
          {{ $t('Timesick.MainSectionTextPart3', {}, { locale: $store.state.lang }) }}
        </p>
      </template>
    </MainSection>
    <MainSection
      :show-buttons="true"
      :primary-color="'#606060'"
      :title="$t('General.Authorization', {}, { locale: $store.state.lang })"
      background-color="#F2F2F2"
      image-url="Auth-Cropped.webp"
      alt-tag-text="LaptopAuthAltText"
      @contact-btn-clicked="contactBtnClicked()"
    >
      <template #main-section>
        <p>
          {{ $t('Timesick.MainSectionTextPart4', {}, { locale: $store.state.lang }) }}
        </p>
      </template>
    </MainSection>
    <MainSection
      :reversed="true"
      :show-buttons="false"
      :primary-color="'#606060'"
      :title="$t('General.ReportSick', {}, { locale: $store.state.lang })"
      :background-color="'white'"
      image-url="Sick-Cropped.webp"
      alt-tag-text="LaptopCompeteSoftWareSickAltTag"
    >
      <template #main-section>
        <p>
          {{ $t('Timesick.ReportSickText', {}, { locale: $store.state.lang }) }}
        </p>
      </template>
    </MainSection>
    <Footer />
  </div>
</template>

<script>
import Footer from '@/components/Footer/Footer'
import MainSection from '@/components/Sections/MainSection'
import AdvantagesSection from '@/components/Sections/AdvantagesSection'
import { useMeta } from 'vue-meta'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

// todo make importing json dynamic

export default {
  name: 'Timesick',
  components: {
    Footer, MainSection, AdvantagesSection
  },
  setup () {
    const store = useStore()

    const { t } = useI18n({
      messages: {
        en: {
          title: 'Complete package',
          description: 'The leave, time and sick registration system for €3,50 per employee per month. With the complete package of Compete HR you can easily register leave, register working hours and . Because employees can submit leave themselves, there is always an overview and there is no uncertainty about the leave balance.'
        },
        nl: {
          title: 'Compleet pakket',
          description: 'Het verlof-, tijd- én ziekregistratiesysteem voor €3,50 per medewerker per maand. Bij het complete pakket van Compete HR kunt u eenvoudig verlof registreren, werkuren registreren en . Doordat medewerkers zelf verlof kunnen indienen, is er altijd overzicht en geen onduidelijkheid over verlofsaldo.'
        }
      }
    })
    useMeta({
      title: t('title', {}, { locale: store.state.lang }),
      description: t('description', {}, { locale: store.state.lang })
    })
  },
  mounted () {
    if (this.$route.name === 'Timesick') {
      this.updateChosenProduct('timesick')
    } else {
      this.$router.push('/404')
    }
  },
  methods: {
    contactBtnClicked () {
      this.$router.push(`/${this.$store.state.lang}/contact`)
    },
    updateChosenProduct (chosenproduct) {
      // Update store to chosen product
      this.$store.commit('updateActiveProduct', chosenproduct)
    }
  }
}
</script>

<style lang="scss" scoped>
.leave-container {
  white-space: pre-line;
}
</style>
