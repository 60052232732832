<template>
  <div class="outer-container">
    <div
      v-for="item in items"
      :key="item.id"
      class="card-container"
    >
      <div class="inner-container">
        <div class="card-header">
          <b>{{ $t(`General.${item.title}`, {}, { locale: $store.state.lang }) }}</b>
        </div>
        <div class="card-main">
          {{ $t(`General.${item.mainContent}`, {}, { locale: $store.state.lang }) }}
        </div>
        <div
          class="card-footer"
        >
          <router-link
            :style="{color: item.primaryColor}"
            class="footer-text"
            :to="item.linkUrl"
          >
            {{ item.linkText ? $t(`General.${item.linkText}`, {}, { locale: $store.state.lang }) + " >>" : "" }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StrongByServiceCard',
  props: {
    items: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="scss" scoped>
.outer-container {
  display: flex;
  flex-wrap: wrap;
  row-gap: 40px;
  justify-content: space-between;
  .card-container {
    background-color: #FFFFFF;
    width:23%;
    position: relative;
    box-shadow: -3px 0px 50px #00000024;
    color: #606060;
    .inner-container {
      padding: 1em 0.5em 0.5em 1em;
      .card-header {
        padding-bottom: 1em;
      }
      .card-main {
        padding-bottom: 2em;
      }
      .card-footer {
        position: absolute;
        bottom:1em;
        right:1em;
        .footer-text {
          text-decoration: none;
        }
      }
    }
  }
}

@media screen and (min-width: 2300px) {
  .outer-container {
    justify-content: space-between;
  }
}

@media screen and (max-width: 915px) {
  .outer-container {
    max-width: 600px;  // So that it becomes impossible to get 3 cards next to each other
    justify-content: center;
    column-gap: 40px;
    .card-container {
      width:230px;
    }
  }
}

</style>
