<template>
  <div
    v-click-outside="closeInfoPopover"
    class="info-popover-container"
    :style="isOpen ?{ 'background-color': '#D6D6D6' } : ''"
  >
    <div
      class="info-icon-container"
      :style="isOpen ?{ 'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 1px 2px 0px'} : ''"
    >
      <i
        class="far fa-info-circle icon"
        :style="[error ? {color: 'red'} : '', isOpen ? {'color': '#FFFFFF'} : '']"
        @click="togglePopover"
      />
    </div>
    <div
      v-if="isOpen"
      class="info-popover-content"
    >
      {{ $t(`Forms.${infoText}`, {}, { locale: $store.state.lang }) }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'InfoPopover',
  props: {
    infoText: {
      type: String,
      default: 'Some info about the field'
    },
    error: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isOpen: false
    }
  },
  methods: {
    togglePopover () {
      this.isOpen = !this.isOpen
    },
    closeInfoPopover () {
      if (this.isOpen) {
        this.togglePopover()
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.info-popover-container {
    position: relative;
    font-size: 16px;
  .info-icon-container {
    width: 30px;
    height: 30px;
  }
    i {
      position: absolute;
      left:0.45em;
      top:0.55em;
      cursor: pointer;

    }
    .info-popover-content {
      position: absolute;
      width: 200px;
      height: 60px;
      background-color: #D6D6D6;
      color: #606060;
      right: 1.8em;
      top: 0em;
      z-index: 1;
      padding: 0.5em;
      box-shadow: -3px 3px 3px rgba(0,0,0,0.14);
    }
}

</style>
