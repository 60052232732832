import { createStore } from 'vuex'
const store = createStore({
  state: {
    activePage: '',
    primaryColor: '',
    menuItems: [{ id: 0, title: 'Products', route: '' }, { id: 1, title: 'Pricing', route: '/pricing' }, { id: 2, title: 'AboutUs', route: '/about' }, { id: 3, title: 'Contact', route: '/contact' }],
    orderList: {}, // orderlist is initially empty
    showConfirmationContent: false,
    oneOffCosts: false,
    productMenuItems: {
      firstSection: [{ title: 'LeaveDays', route: 'leave', readyforsale: true }, { title: 'LeaveTime', route: 'time', readyforsale: true }, { title: 'LeaveSick', route: 'sick', readyforsale: true }, { title: 'Panic', route: 'panic', linktosite: true }, { title: 'LeaveTimeSick', route: 'timesick', readyforsale: true }]
    },
    lang: 'nl',
    productId: '', // this id can be used to detect which product is active
    productName: '', // this updates based on the chosen product,
    productOrderItems: [], // each product has its own specific orderitems
    productData: '', // this loads the json product data based on chosen product
    activeModule: '', // this updates based on the chosen product
    shoppingCard: '',
    showLanguages: false,
    showProductsMenu: false,
    showHamburger: false,
    showMobileProductsMenu: false,
    buildPackage: true,
    advisedPackage: 'leave',
    emailAddress: '',
    phoneNr: '',
    userName: '',
    companyName: '',
    nrOfEmployees: '',
    website: '',
    name: '',
    message: '',
    messageEmail: '',
    messagePhoneNr: '',
    checkboxMessage: '',
    websiteDomain: 0,
    terms: false,
    foundVia: 'MakeYourChoice',
    clickedShowProducts: false,
    invalidFields: [],
    invalidMessageFields: [],
    selectedProducts: [],
    checkboxArray: []
  },
  mutations: {
    updateActivePage (state, pagename) {
      state.activePage = pagename
      state.showProductsMenu = false
      state.activeModule = ''
    },
    updateActiveProduct (state, product) {
      const productData = require('@/json/' + product + '.json') // this imports the json file of the chosenproduct dynamically
      state.activeModule = product
      state.productData = productData
      state.productId = productData.Id
      state.productName = productData.ProductName
      state.primaryColor = productData.PrimaryColor
      state.productOrderItems = productData.OrderItems
    },
    updateShoppingCard (state, product) {
      state.shoppingCard = product
    },
    updateLanguage (state, value) {
      state.lang = value
    },
    addItemOrderList (state, item) {
      state.orderList[state.activeModule].push(item)
    },
    deleteItemOrderList (state, index) {
      state.orderList[state.activeModule].splice(index, 1)
    },
    updateOrderList (state, value) {
      state.orderList = value
    },
    toggleshowConfirmationContent (state) {
      state.showConfirmationContent = !state.showConfirmationContent
    },
    closeHandler (state, value) {
      state[value] = false
    },
    toggleHandler (state, value) {
      state[value] = !state[value]
    },
    updateOneOffCosts (state, value) {
      state.oneOffCosts = value
    },
    update_emailAddress (state, value) {
      state.emailAddress = value
    },
    update_phoneNr (state, value) {
      state.phoneNr = value
    },
    update_userName (state, value) {
      state.userName = value
    },
    update_companyName (state, value) {
      state.companyName = value
    },
    update_nrOfEmployees (state, value) {
      state.nrOfEmployees = value
    },
    update_website (state, value) {
      state.website = value
    },
    update_websiteDomain (state, value) {
      state.websiteDomain = value
    },
    update_terms (state, value) {
      state.terms = value
    },
    update_foundVia (state, value) {
      state.foundVia = value
    },
    update_name (state, value) {
      state.name = value
    },
    update_message (state, value) {
      state.message = value
    },
    update_messageEmail (state, value) {
      state.messageEmail = value
    },
    update_messagePhoneNr (state, value) {
      state.messagePhoneNr = value
    },
    update_checkboxMessage (state, value) {
      state.checkboxMessage = value
    },
    update_advisedPackage (state, value) {
      state.advisedPackage = value
    },
    update_buildPackage (state, value) {
      state.buildPackage = value
    },
    update_selectedProducts (state, value) {
      const index = state.selectedProducts.indexOf(value)
      if (index === -1) {
        state.selectedProducts.push(value)
      } else {
        state.selectedProducts.splice(index, 1)
      }
    },
    update_checkboxArray (state, value) {
      const index = state.checkboxArray.indexOf(value)
      if (index === -1) {
        state.checkboxArray.push(value)
      } else {
        state.checkboxArray.splice(index, 1)
      }
    },
    setFormFieldInvalid (state, value) {
      if (value.inError) {
        const index = state.invalidFields.findIndex(f => f.fieldId === value.fieldId)
        if (index === -1) {
          state.invalidFields.push(value)
        }
      } else {
        const index = state.invalidFields.findIndex(f => f.fieldId === value.fieldId)
        state.invalidFields.splice(index, 1)
      }
    },
    setDropDownFieldInvalid (state, value) {
      if (value.inError) {
        const index = state.invalidFields.findIndex(f => f.fieldId === value.fieldId)
        if (index === -1) {
          state.invalidFields.push(value)
        }
      } else {
        const index = state.invalidFields.findIndex(f => f.fieldId === value.fieldId)
        state.invalidFields.splice(index, 1)
      }
    },
    setSiteNameFieldInvalid (state, value) {
      if (value.inError) {
        const index = state.invalidFields.findIndex(f => f.fieldId === value.fieldId)
        if (index === -1) {
          state.invalidFields.push(value)
        }
      } else {
        const index = state.invalidFields.findIndex(f => f.fieldId === value.fieldId)
        state.invalidFields.splice(index, 1)
      }
    },
    setMessageFormFieldInvalid (state, value) {
      if (value.inError) {
        const index = state.invalidMessageFields.findIndex(f => f.fieldId === value.fieldId)
        if (index === -1) {
          state.invalidMessageFields.push(value)
        }
      } else {
        const index = state.invalidMessageFields.findIndex(f => f.fieldId === value.fieldId)
        if (index >= 0) {
          state.invalidMessageFields.splice(index, 1)
        }
      }
    }
  },
  getters: {
    activeModuleFormatted: state => {
      const activemodule = state.activeModule
      const formattedActiveModule = activemodule.charAt(0).toUpperCase() + activemodule.slice(1)
      return formattedActiveModule
    },
    formIsValid: (state, getters) => {
      return (state.invalidFields.length === 0) && ((getters.hasTerminal && (getters.hasTimeCard || getters.hasTimeTag)) || !getters.hasTerminal)
    },
    messageFormIsValid: state => {
      return (state.invalidMessageFields.length === 0)
    },
    hasTimeCard: state => {
      return (state.productOrderItems.findIndex(f => f.title === 'TimeCard' && f.amount > 0) > -1)
    },
    hasTimeTag: state => {
      return (state.productOrderItems.findIndex(f => f.title === 'TimeTag' && f.amount > 0) > -1)
    },
    hasTerminal: state => {
      return (state.productOrderItems.findIndex(f => f.title === 'Terminal' && f.amount > 0) > -1)
    }
  }
})

export default store
