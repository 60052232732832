<template>
  <div class="container">
    <div class="content">
      <Heading
        :text="$t('General.TermsAndConditions', {}, { locale: $store.state.lang })"
        primary-color="#1DAAE1"
        class="margin-top heading"
      />
      <div>
        <h3>{{ $t('General.Dutch', {}, { locale: $store.state.lang }) }}</h3>
        <div>
          Databescherming & technische details (<a href="/files/Databescherming&technische_details.docx"
          download="">Word</a> | <a href="/files/Databescherming&technische_details.pdf"
          download="">Pdf</a>)
        </div>
        <br>
        <div>
            Nederland ICT voorwaarden (<a href="/files/Nederland_ICT_Voorwaarden.docx"
            download="">Word</a> | <a href="/files/Nederland_ICT_Voorwaarden_2014.pdf"
            download="">Pdf</a>)
        </div>
        <br>
        <div>
            VD Algemene voorwaarden (<a href="/files/VD_algemene_voorwaarden.pdf"
            download="">Pdf</a>)
        </div>
        <br>
        <h3>{{ $t('General.English', {}, { locale: $store.state.lang }) }}</h3>
        <div>
            Data Protection & Technical Details (<a href="/files/Data_Protection&Technical_Details.docx"
            download="">Word</a> | <a href="/files/Data_Protection&Technical_Details.pdf" download="">Pdf</a>)
        </div>
        <br>
        <div>
            Data Protection LeaveDays (<a href="/files/Data_protection_Leavedays.docx"
            download="">Word</a> | <a href="/files/DataProtectionLeaveDays.pdf" download="">Pdf</a>)
        </div>
        <br>
        <div>
            Data Protection And Implementation Agreements Leavedays (<a href="/files/Data_protection_SLA_and_Implementation_Agreements_Leavedays.docx"
            download="">Word</a> | <a href="/files/Data_protection_SLA_and_Implementation_Agreements_Leavedays.pdf" download="">Pdf</a>)
        </div>
        <br>
        <div>
            VD general terms and conditions (<a href="/files/VD_general_terms_and_conditions.pdf"
            download="">Pdf</a>)
        </div>
      </div>
    </div>
    <Footer id="footer" />
  </div>
</template>

<script>
import Footer from '@/components/Footer/Footer'
import Heading from '@/components/Heading/Heading'

export default {
  name: 'TermsAndConditions',
  components: {
    Footer, Heading
  },
  data () {
    return {
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  min-height: calc(100vh - 75px); //75px is height navbar
}
#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 2em;
  padding-bottom: 16rem;
}
a {
    text-decoration: none;
    color: inherit;
}
// From a width of 1066px show the ipad variant
@media screen and (max-width: 1066px) {
  .container{
    min-height: calc(100vh - 50px); // 50px is height topbar
  }
}
</style>
