<template>
  <div class="leave-container">
    <MainSection
      :title="$t('Leave.ProductName', {}, { locale: $store.state.lang })"
      :subheader="$t('Leave.SubHeaderText', {}, { locale: $store.state.lang })"
      :btn-text="$t('General.ContactUsBtn', {}, { locale: $store.state.lang })"
      :sub-heading="true"
      image-url="Leave-Desktop-Banner-Cropped.webp"
      :no-padding-img="true"
      alt-tag-text="LapTopLeaveSoftwareAltTag"
      @contact-btn-clicked="contactBtnClicked()"
    >
      <template #main-section>
        <p>
          {{ $t('Leave.MainSectionText', {}, { locale: $store.state.lang }) }}
        </p>
      </template>
    </MainSection>
    <MoreEfficient
      :title="$t('General.WorkMoreEfficiently', {}, { locale: $store.state.lang })"
      :primary-color="'#1DAAE1'"
      @contact-btn-clicked="contactBtnClicked()"
    >
      <template #advantages>
        <p>
          <ul>
            <li>{{ $t('Leave.MoreEfficient', {}, { locale: $store.state.lang }) }}</li>
            <li>{{ $t('Leave.MoreEfficient2', {}, { locale: $store.state.lang }) }}</li>
            <li>{{ $t('Leave.MoreEfficient3', {}, { locale: $store.state.lang }) }}</li>
            <li>{{ $t('Leave.MoreEfficient4', {}, { locale: $store.state.lang }) }}</li>
            <li>{{ $t('Leave.MoreEfficient5', {}, { locale: $store.state.lang }) }}</li>
          </ul>
        </p>
      </template>
    </MoreEfficient>
    <MainSection
      :show-buttons="false"
      :primary-color="'#606060'"
      :title="$t('Leave.MainSectionTitlePart2', {}, { locale: $store.state.lang })"
      :background-color="'white'"
      image-url="Leave-Desktop-Cropped.webp"
      alt-tag-text="LaptopCompeteSoftWareAltTag"
    >
      <template #main-section>
        <p>
          {{ $t('Leave.MainSectionTextPart2', {}, { locale: $store.state.lang }) }}
        </p>
      </template>
    </MainSection>
    <MainSection
      :reversed="true"
      :show-buttons="true"
      :primary-color="'#606060'"
      :background-color="'white'"
      :title="$t('General.MobileAppSectionTitle', {}, { locale: $store.state.lang })"
      image-url="Leave-Mobile-Cropped.webp"
      alt-tag-text="MobileLeaveSoftwareAltTag"
      :margin="false"
      @contact-btn-clicked="contactBtnClicked()"
    >
      <template #main-section>
        <p>
          {{ $t('Leave.MobileAppSectionText', {}, { locale: $store.state.lang }) }}
        </p>
      </template>
    </MainSection>
    <MainSection
      :show-buttons="false"
      :primary-color="'#606060'"
      :title="$t('General.DifferentLanguages', {}, { locale: $store.state.lang })"
      :margin="false"
      image-url="Languages-Cropped.webp"
      alt-tag-text="MobileLanguagesAltTag"

    >
      <template #main-section>
        <p>
          {{ $t('General.DifferentLanguageText', {}, { locale: $store.state.lang }) }}
        </p>
      </template>
    </MainSection>
    <MainSection
      :reversed="true"
      :show-buttons="true"
      :try-buy-buttons="true"
      :double-buttons="false"
      :primary-color="'#606060'"
      :margin="false"
      :title="$t('General.GiveUpOvertime', {}, { locale: $store.state.lang })"
      image-url="Leave-Overtime-Cropped.webp"
      alt-tag-text="LaptopOverTimeAltTag"
      @contact-btn-clicked="contactBtnClicked()"
    >
      <template #main-section>
        <p>
          {{ $t('Leave.MainSectionTextPart3', {}, { locale: $store.state.lang }) }}
        </p>
      </template>
    </MainSection>
    <MainSection
      :show-buttons="false"
      :primary-color="'#606060'"
      :title="$t('General.Authorization', {}, { locale: $store.state.lang })"
      background-color="white"
      image-url="Auth-Cropped.webp"
      alt-tag-text="LaptopAuthAltText"
    >
      <template #main-section>
        <p>
          {{ $t('Leave.MainSectionTextPart4', {}, { locale: $store.state.lang }) }}
        </p>
      </template>
    </MainSection>
    <Footer />
  </div>
</template>

<script>
import Footer from '@/components/Footer/Footer'
import MainSection from '@/components/Sections/MainSection'
import MoreEfficient from '@/components/Sections/MoreEfficient'
import { useMeta } from 'vue-meta'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

// todo make importing json dynamic

export default {
  name: 'Leave',
  components: {
    Footer, MainSection, MoreEfficient
  },
  setup () {
    const store = useStore()

    const { t } = useI18n({
      messages: {
        en: {
          title: 'Leavedays',
          description: 'A complete leave system for €2 per employee per month. In the leave application of Compete HR you can easily register leave. Because employees can submit leave themselves, there is always an overview and no ambiguity about leave balance.'
        },
        nl: {
          title: 'Vrijedagen',
          description: 'Een compleet verlofsysteem voor €2,- per medewerker per maand. In de verloftoepassing van Compete HR kunt u eenvoudig verlof registreren. Doordat medewerkers zelf verlof kunnen indienen is er altijd overzicht en geen onduidelijkheid over verlofsaldo.'
        }
      }
    })
    useMeta({
      title: t('title', {}, { locale: store.state.lang }),
      description: t('description', {}, { locale: store.state.lang })
    })
  },
  mounted () {
    if (this.$route.name === 'Leave') {
      this.updateChosenProduct('leave')
    } else {
      this.$router.push('/404')
    }
  },
  methods: {
    contactBtnClicked () {
      this.$router.push(`/${this.$store.state.lang}/contact`)
    },
    updateChosenProduct (chosenproduct) {
      // Update store to chosen product
      this.$store.commit('updateActiveProduct', chosenproduct)
    }
  }
}
</script>

<style lang="scss" scoped>
.leave-container {
  white-space: pre-line;
}
</style>
