<template>
  <div class="container">
    <div class="content-container">
      <div class="content">
        <Heading
          :text="$t('General.404Title', {}, { locale: $store.state.lang })"
          primary-color="#1DAAE1"
          class="margin-top heading"
        />
        <p class="margin-top main-text">
          {{ $t('General.404Message', {}, { locale: $store.state.lang }) }}
        </p>
        <router-link
          to="/"
          class="link-home margin-top"
        >
          <CompeteButton
            :text="$t('General.BackToHome', {}, { locale: $store.state.lang })"
            :background-color="'#1DAAE1'"
            :variation="'squareCorners'"
            class="btn"
          />
        </router-link>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from '@/components/Footer/Footer'
import Heading from '@/components/Heading/Heading'
import CompeteButton from '@/components/CompeteButton'
import { useMeta } from 'vue-meta'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

export default {
  name: 'PageNotFound',
  components: {
    Footer, Heading, CompeteButton
  },
  setup () {
    const store = useStore()

    const { t } = useI18n({
      messages: {
        en: {
          title: 'Oops, page not found'
        },
        nl: {
          title: 'Oeps, pagina niet gevonden'
        }
      }
    })
    useMeta({
      title: t('title', {}, { locale: store.state.lang }),
      meta: [
        { name: 'robots', content: 'noindex' }
      ]
    })
  }
}
</script>

<style lang="scss" scoped>
.container {
  height: 97vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.link-home {
  text-decoration: none;
  display: flex;
  justify-content: center;
}
.btn {
  width: 10em;
}
.margin-top {
    margin-top: 2em;
}
.main-text {
  text-align: center;
}

@media screen and (max-width: 740px) {
  .content-container {
    display: grid;
    grid-template-columns: 0.3fr 9.4fr 0.3fr;
    grid-template-areas: '. content .'
                          '. main-text .'
                          '. link-home .';
    margin-bottom: 30vh;
    .content {
      grid-area: content;
    }
  }
}

</style>
