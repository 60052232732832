<template>
  <div class="captcha">
    <VueRecaptcha
      sitekey="6LeKRnkhAAAAAO0Jnl3dqeBeVvA9mHmZdAunNMTv"
      :load-recaptcha-script="true"
      @verify="handleSuccess"
      @error="handleError"
      @expired="handleError"
    />
  </div>
</template>

<script lang="js">
import { defineComponent } from 'vue'
import { VueRecaptcha } from 'vue-recaptcha'

export default defineComponent({
  name: 'ReCaptcha',
  components: {
    VueRecaptcha
  },
  methods: {
    handleSuccess () {
      this.$store.commit(
        'update_checkboxMessage',
        true
      )
      this.$store.commit('setMessageFormFieldInvalid', { inError: false, fieldId: 'checkboxMessage' })
    },
    handleError () {
      this.$store.commit(
        'update_checkboxMessage',
        false
      )
      this.$store.commit('setMessageFormFieldInvalid', { inError: true, fieldId: 'checkboxMessage' })
    }
  }
})
</script>

<style lang="scss" scoped>
  .captcha {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1em;
  }
</style>
