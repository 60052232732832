<template>
  <div
    class="main-section"
  >
    <div
      class="main-content-reversed"
    >
      <div class="heading-logo">
        <div class="heading">
          <Heading
            :text="title"
            :primary-color="primaryColor"
          />
        </div>
        <div
          v-if="logoUrl == 'Time' || logoUrl == 'Sick'"
          class="product-logo"
        >
          <img
            :src="require(`@/assets/images/${logoUrl}-Logo.webp`)"
            :alt="altTagLogoText"
          >
        </div>
        <div
          v-else
          class="product-logo"
        >
          <img
            :src="require(`@/assets/images/Leave-Logo.webp`)"
            :alt="$t('General.LeaveLogoAltTag', {}, { locale: $store.state.lang })"
          >
          <img
            :src="require(`@/assets/images/Time-Logo.webp`)"
            :alt="$t('General.TimeLogoAltTag', {}, { locale: $store.state.lang })"
          >
          <img
            :src="require(`@/assets/images/Sick-Logo.webp`)"
            :alt="$t('General.SickLogoAltTag', {}, { locale: $store.state.lang })"
          >
        </div>
      </div>
      <div class="paragraph">
        <slot name="main-section" />
      </div>
      <div
        class="try-buy-btns"
      >
        <CompeteButton
          :text="$t('General.RequestDemo', {}, { locale: $store.state.lang })"
          :background-color="'#1DAAE1'"
          :variation="'squareCorners'"
          class="first-btn"
          @btn-clicked="$emit('contact-btn-clicked')"
        />
      </div>
      <div class="main-image">
        <img
          :src="require(`@/assets/images/${imageUrl}`)"
          :alt="altTagText"
        >
      </div>
    </div>
  </div>
</template>

<script>
import Heading from '@/components/Heading/Heading'
import CompeteButton from '@/components/CompeteButton'

export default {
  name: 'MainSection',
  components: {
    Heading, CompeteButton
  },
  props: {
    title: {
      type: String,
      default: 'Title'
    },
    primaryColor: {
      type: String,
      default: '#606060'
    },
    imageUrl: {
      type: String,
      default: 'Main.webp'
    },
    logoUrl: {
      type: String,
      default: 'Leave'
    },
    altTagText: {
      type: String,
      default: 'LaptopFrontImg'
    },
    altTagLogoText: {
      type: String,
      default: 'LeaveLogoAltTag'
    }

  }
}
</script>

<style lang="scss" scoped>
  .main-section {
    .main-content-reversed {
      display: grid;
      grid-template-areas: '. main-image heading-logo .'
                           '. main-image paragraph .'
                           '. main-image try-buy .' ;
      grid-template-columns: 1.8fr 3.2fr 3.2fr 1.8fr;
      grid-template-rows: 4em auto;
      .heading-logo {
        grid-area: heading-logo;
        padding-left:1em;
        position: relative;
        .product-logo {
          position: absolute;
          right: 0;
          top: 1em;
          img {
            height: 3.5em;
            margin-left: 0.5em;
          }
        }
      }
      .paragraph {
        grid-area: paragraph;
        padding-left:1em;
      }
      .try-buy-btns {
        grid-area: try-buy;
        width: 100%;
        padding-top:1em;
        padding-left: 1em;
      }
      .first-btn {
        float:left;
      }
      .second-btn {
        float: left;
        margin-left: 0.5em;
      }
      .main-image {
        grid-area: main-image;
        padding-top:1em;
        width: 100%;
        height: calc(100% - 1em);
        img {
          display: block; // this is to remove the extra white space underneath an image
          width: 100%;
          height: 100%;
          object-fit: cover;
          image-rendering: -moz-crisp-edges;         /* Firefox */
          image-rendering:   -o-crisp-edges;         /* Opera */
          image-rendering: -webkit-optimize-contrast;/* Webkit (non-standard naming) */
          image-rendering: crisp-edges;
          -ms-interpolation-mode: nearest-neighbor;  /* IE (non-standard property) */
        }
      }
    }
  }

  @media screen and (min-width: 2300px) {
    .main-section {
      min-height: 28vh;
      display: flex;
      justify-content: center;
      align-items: center;
      .main-content-reversed {
        grid-template-rows: auto auto auto;
        max-width: 2300px;
        .heading {
          align-self: start; // vertical alignment
        }
        .main-image, .paragraph {
          align-self: center; // vertical alignment
          justify-self: start;  // horizontal alignment
        }
      }
    }
  }
@media screen and (max-width: 1500px) {
  .main-section {
    .main-content-reversed {
      grid-template-columns: 0.8fr 4.2fr 4.2fr 0.8fr;
    }
  }
}

// From a width of 1066px show the ipad variant
@media screen and (max-width: 1066px) {
  .main-section {
    .main-content-reversed {
      grid-template-columns: 0.3fr 4.7fr 4.7fr 0.3fr;
      grid-template-rows: auto auto auto;
      .paragraph {
        padding-left: 2em;
      }
      .heading-logo {
        padding-left: 1.7em;
        .product-logo {
          img {
            height: 3em;
          }
        }
      }
      .try-buy-btns {
        padding-left: 1.3em;
      }
      .main-image{
        img{
            width: 100%;
        }
      }
    }
  }
}

// From a width of 740px show the mobile variant
@media screen and (max-width: 740px) {
  .main-section {
    .main-content-reversed {
      grid-template-columns: 0.3fr 9.4fr 0.3fr;
      grid-template-areas: '. main-image .'
                           '. heading-logo .'
                           '. paragraph .'
                           '. try-buy .' ;
      grid-template-rows: auto auto auto;
      .main-image {
        padding-top:1.5em;
        padding-bottom:0em;
        align-self: center; // vertical alignment
        justify-self: center;  // horizontal alignment
      }
      .heading-logo {
        padding-left: 0;
      }
      .paragraph {
        padding-left:0;
        margin-top: -0.3em;
      }
      .try-buy-btns {
        padding-left: 0;
      }
    }
  }
}
</style>
