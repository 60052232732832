<template>
  <section>
    <metainfo>
      <template #title="{ content }">
        {{
          content ? `${content} | Compete HR` : `Compete HR`
        }}
      </template>
    </metainfo>
    <div class="app-container">
      <div class="sticky-nav">
        <NavBar
          :menu-items="menuItems"
          :color="'#FFFFFF'"
          :primary-color="'#1DAAE1'"
          @menu-item-clicked="menuItemClicked"
          @toggle-mobile-menu="toggleMobileMenu"
        />
        <NavigationPopup
          v-show="$store.state.showProductsMenu"
          v-click-outside="closeNavigationPopup"
          @close-navigation-popup="closeNavigationPopup"
        />
      </div>
      <div id="app">
        <!-- Link to font-family din-2014 Demi -->
        <link
          href="https://use.typekit.net/zzc8fmd.css"
          rel="stylesheet"
          type="text/css"
        >
        <router-view v-if="!$store.state.showHamburger" />
        <div v-if="$store.state.showHamburger">
          <MobileMenu
            :show-mobile-products-menu="$store.state.showMobileProductsMenu"
            @menu-item-clicked="menuItemClicked"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import NavBar from '@/components/Header/NavBar'
import NavigationPopup from '@/components/Header/NavigationPopup'
import MobileMenu from '@/components/Header/MobileMenu'

export default {
  name: 'App',
  metaInfo () {
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: 'Default Title',
      // all titles will be injected into this template
      titleTemplate: '%s | My Awesome Webapp'
    }
  },
  components: {
    NavBar,
    NavigationPopup,
    MobileMenu
  },
  setup () {
  },
  data () {
    return {
      menuItems: []
    }
  },
  mounted () {
    this.menuItems = this.$store.state.menuItems
  },
  methods: {
    menuItemClicked (menuitem) {
      if (menuitem.id === 0) {
        // toggle the products menu when the first item is clicked
        this.$store.commit('toggleHandler', 'showProductsMenu')
        // close languages dropdown when navigation popup opens (this is the reason why this is arranged from the store)
        this.$store.commit('closeHandler', 'showLanguages')
      }
      if (menuitem.id === 0 && this.$store.state.showHamburger) {
        // Code to show/hide mobile products menu
        this.$store.state.showMobileProductsMenu
          ? this.$store.commit('closeHandler', 'showMobileProductsMenu')
          : this.$store.commit('toggleHandler', 'showMobileProductsMenu')
      } else if (menuitem.id !== 0 && this.$store.state.showHamburger) {
        this.$store.commit('closeHandler', 'showHamburger')
      }
    },
    closeNavigationPopup () {
      if (
        this.$store.state.showProductsMenu &&
        !this.$store.state.clickedShowProducts
      ) {
        this.$store.commit('closeHandler', 'showProductsMenu')
      }
      this.$store.commit('closeHandler', 'clickedShowProducts')
    },
    toggleMobileMenu () {
      if (this.$store.state.showHamburger) {
        this.$store.commit('closeHandler', 'showHamburger')
        this.$store.commit('closeHandler', 'showMobileProductsMenu')
      } else {
        this.$store.commit('toggleHandler', 'showHamburger')
      }
    }
  }
}
</script>

<style lang="scss">
body,
html {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'din-2014', Tahoma;
  color: #606060;
  font-weight: 300;
  font-size: 18px;
  font-display: auto;
}

#app {
  height: 100%;
}

.app-container {
  margin: auto;

  .sticky-nav {
    background-color: #ffffff;
    position: sticky;
    top: 0;
    z-index: 3;
  }
}

@media screen and (min-width: 2300px) {
  .app-container {
    min-height: 100vh;
  }
}
@media screen and (max-width: 1060px) {
  body,
  html {
    font-size: 14px;
  }
}
</style>
