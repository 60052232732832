<template>
  <div
    :class="[mainSection ? notFullWidth : fullWidth]"
  >
    <div
      v-if="variation === 'roundCorners'"
      class="button-round"
      :style="{'background-color': backgroundColor, 'color': color, 'border-color': color}"
      @click="$emit('btn-clicked')"
    >
      {{ text }}
    </div>
    <div
      v-if="variation === 'squareCorners'"
      class="button-square"
      :style="[{'background-color': backgroundColor, 'color': color}, !errorFree ? {'cursor': 'not-allowed'} : {'cursor': 'pointer'}]"
      @click="$emit('btn-clicked')"
    >
      {{ text }}
    </div>
    <div
      v-if="variation === 'pricing'"
      class="button-pricing"
      :style="[{'background-color': backgroundColor, 'color': color}, !errorFree ? {'cursor': 'not-allowed'} : {'cursor': 'pointer'}]"
      @click="$emit('btn-clicked')"
    >
      {{ text }}
    </div>
    <div
      v-if="variation === 'onlyText'"
      class="button-only-text"
      :style="{'color': color}"
    >
      <span>{{ text }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CompeteButton',
  props: {
    backgroundColor: {
      type: String,
      default: '#FFFFFF'
    },
    color: {
      type: String,
      default: '#FFFFFF'
    },
    text: {
      type: String,
      default: 'Probeer'
    },
    errorFree: {
      type: Boolean,
      default: true
    },
    mainSection: {
      type: Boolean,
      default: false
    },
    variation: {
      type: String,
      default: 'roundCorners',
      validator: value => {
        return value.match(/(roundCorners|squareCorners|onlyText|pricing)/)
      }
    }
  },
  data () {
    return {
      fullWidth: 'button-container',
      notFullWidth: 'non-full-width'
    }
  }
}
</script>

<style lang="scss" scoped>
.button-container, .non-full-width {
  font-weight: 400;
}
.button-round {
  width: 108px;
  height: 30px;
  text-align: center;
  line-height: 30px; // to vertically align the text in the div
  border-radius: 15px;
  border: 1px solid;
}
.button-round:hover {
  cursor: pointer;
}
.button-container {
  width: 48%;
}
.non-full-width {
  width: 40%;
}
.button-square {
  width: 100%;
  height: 2.5em;
  text-align: center;
  line-height: 2.5em; // to vertically align the text in the div
  border: 1px solid;
}
.button-square:hover {
  cursor: pointer;
}
.button-pricing {
  width: 100%;
  height: 2.5em;
  text-align: center;
  line-height: 2.5em; // to vertically align the text in the div
  border: 1px solid;
  border-radius: 15px;
}
.button-pricing:hover {
  cursor: pointer;
}
.button-only-text {
  width: 100%;
  text-align: center;
  height: 2.5em;
  line-height: 2.5em; // to vertically align the text in the div
}
.button-only-text span:hover {
  cursor: pointer;
}

@media screen and (max-width: 740px) {
  .non-full-width {
    width: 48%;
  }
}
</style>
