<template>
  <div class="topmenu-container">
    <div class="menu-container">
      <ul
        v-for="item in menuItems"
        :key="item"
        class="menu-items"
      >
        <router-link
          :to="item.title == 'Products' ? item.route : `/${$store.state.lang}${item.route}`"
          class="menu-item"
          @click.stop
        >
          <li
            class="menu-item"
            @click="menuItemClicked(item)"
          >
            {{ $t(`General.${item.title}`, {}, { locale: $store.state.lang }) }}
            <div
              :style="[$store.state.showProductsMenu && item.id === 0 ? {'background-color': '#1DAAE1'} : '']"
              class="blue-bottom-border"
            />
          </li>
        </router-link>
      </ul>
      <div class="menu-items">
        <a
          class="menu-item margin-left"
          :href="$t('General.SupportURL', {}, { locale: $store.state.lang })"
          target="_blank"
        >{{ $t('General.Support', {}, { locale: $store.state.lang }) }}
        </a>
      </div>
    </div>
    <div class="language-switcher">
      <LanguageSwitcher />
    </div>
  </div>
</template>

<script>
import LanguageSwitcher from '@/components/Header/LanguageSwitcher'
export default {
  name: 'TopMenu',
  components: {
    LanguageSwitcher
  },
  props: {
    menuItems: {
      type: Array,
      default: () => ['test1', 'test2']
    }
  },
  computed: {
    shoppingCardItems () {
      return this.$store.state.shoppingCard
    }
  },
  methods: {
    menuItemClicked (item) {
      this.$emit('menu-item-clicked', item)
    },
    shoppingCardLink () {
      if (this.$store.state.shoppingCard) {
        this.$router.push(`/order/${this.$store.state.shoppingCard}`)
      } else {
        this.$router.push('/emptycard')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.topmenu-container {
  width:100%;
  margin-top:3px; // to keep the position the same after adding the blue bottom order
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  .language-switcher {
    padding-top:0.45em;
  }
  .shopping-card {
    padding-top: 1em;
    padding-right: 1em;
    justify-self: flex-end;
    color: #606060;
    position: relative;
    .shopping-card-number{
      position: absolute;
      font-size: 10px;
      background-color: #1DAAE1;
      color: white;
      top: 16px;
      right: 12px;
      padding: 0 4px;
      border-radius: 100px;
    }
  }
  .shopping-card:hover {
    cursor: pointer;
  }
  .menu-items {
      display: inline-block;
      color: #606060;
      list-style: none;
      .menu-item {
        text-decoration: none;
        color: #606060;
        cursor: pointer;
      }
      .blue-bottom-border {
        height: 3px;
        position: relative;
      }
  }
  .margin-left {
    margin-left: 2em;
  }
}

// From a width of 1066px show the ipad/mobile variant
@media screen and (max-width: 1066px) {
  .topmenu-container {
    width: 30vw;
    justify-content: space-between;
    margin-top: 0;
    .menu-items {
      display: none;
    }
    .language-switcher {
      padding-top:0;
    }
    .shopping-card {
      padding-top: 0;
      .shopping-card-number{
        top: -2px;
      }
    }
  }
}
@media screen and (max-width: 580px) {
  .topmenu-container {
    widows: 30vw;
    margin-right: 1em;
    .language-switcher {
      padding-right: 0;
    }
    .shopping-card{
      margin-left: 1em;
    }
  }
}

</style>
