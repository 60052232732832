<template>
  <div>
    <PricingCardSection :items="pricingcards" />
    <Footer id="footer" />
  </div>
</template>

<script>
import PricingCardSection from '@/components/Sections/PricingCardSection'
import Footer from '@/components/Footer/Footer'
import { useMeta } from 'vue-meta'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
export default {
  name: 'Pricing',
  components: {
    Footer,
    PricingCardSection
  },
  setup () {
    const store = useStore()

    const { t } = useI18n({
      messages: {
        en: {
          title: 'Pricing',
          description: 'Take advantage of our Professional subscription and experience the benefits of the Leave and Sick modules. Or choose our Premium plan and enjoy the All-in-1 package.'
        },
        nl: {
          title: 'Prijslijst',
          description: 'Profiteer van ons Professional abonnement en ervaar de voordelen van de Verlof en Ziek modules. Of kies voor ons Premium abonnement en geniet van het Alles-in-1 pakket.'
        }
      }
    })
    useMeta({
      title: t('title', {}, { locale: store.state.lang }),
      description: t('description', {}, { locale: store.state.lang })
    })
  },
  data () {
    return {
      pricingcards: [
        {
          id: 0,
          title: 'Professional',
          subtitle: 'LeaveSick',
          price: '2',
          listfirstpart: [
            'ProfessionalListPart1Item1',
            'ProfessionalListPart1Item2',
            'ProfessionalListPart1Item3',
            'ProfessionalListPart1Item4'
          ],
          listsecondpart: [
            'ProfessionalListPart2Item1',
            'ProfessionalListPart2Item2',
            'ProfessionalListPart2Item3',
            'ProfessionalListPart2Item4',
            'ProfessionalListPart2Item5'
          ],
          mostchosen: true
        },
        {
          id: 1,
          title: 'Premium',
          subtitle: 'LeaveSickTime',
          price: '3,50',
          listfirstpart: [
            'PremiumListPart1Item1',
            'PremiumListPart1Item2',
            'PremiumListPart1Item3',
            'PremiumListPart1Item4'
          ],
          listsecondpart: [
            'PremiumListPart2Item1',
            'PremiumListPart2Item2',
            'PremiumListPart2Item3'
          ],
          mostchosen: false
        }
      ]
    }
  },
  mounted () {
    this.$store.commit('updateActivePage', 'Pricing')
  }
}
</script>

<style lang="scss" scoped></style>
