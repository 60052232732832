<template>
  <div class="navigation-popup">
    <!-- NAVIGATION POPUP -->
    <div class="left-menu">
      <span class="title">
        {{ $t(`General.${title}`, {}, { locale: $store.state.lang }) }}
      </span>
      <div class="navigation-items">
        <div
          v-for="(navigationitem,index) in productMenuItemsFirstSection"
          :key="index"
          class="navigation-item"
        >
          <router-link
            v-if="navigationitem.readyforsale"
            :to="`/${$store.state.lang}/product/${navigationitem.route}`"
            class="item"
            @click="updateActiveProduct(navigationitem.route)"
          >
            {{ $t(`General.${navigationitem.title}`, {}, { locale: $store.state.lang }) }}
          </router-link>
          <a
            v-else-if="navigationitem.linktosite"
            href="https://panieksignalering.nl/"
            target="_blank"
            @click="updateActiveProduct(navigationitem.route)"
          >  {{ $t(`General.${navigationitem.title}`, {}, { locale: $store.state.lang }) }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NavigationPopup',
  props: {
    title: {
      type: String,
      default: 'OurProducts'
    }
  },
  data () {
    return {
      productMenuItemsFirstSection: this.$store.state.productMenuItems.firstSection
    }
  },
  methods: {
    updateActiveProduct (activeProduct) {
      this.$emit('close-navigation-popup')
      this.$store.commit('updateActiveProduct', activeProduct)
    }
  }
}
</script>

<style lang="scss" scoped>
.navigation-popup{
  width:286px;
  height: 312px;
  position: absolute;
  left: 30.3vw;
  box-shadow: #00000024 0px 25px 50px -12px;
  color: #606060;
  .left-menu{
    background-color: #FFFFFF;
    float:left;
    width: 100%;
    height: 100%;
    position: relative;
    .title {
      font-weight: bold;
      position: absolute;
      top:1.8em;
      left:1.5vw // used vw here to align the title correctly with brandname on different screens
    }
    .navigation-items {
      position: absolute;
      top:4.6em;
      left: 1.6vw; // used vw here to align the items correctly with brandname and title on different screens
      .navigation-item{
        width: 100%;
        float: left;
        padding-bottom:1.2em;
        .item {
          cursor: pointer;
          text-decoration: none;
          color: #606060;
        }
        a {
          color: #606060;
          text-decoration: none;
        }
      }
    }
  }
}

/* To position nav pop up correctly */
@media screen and (min-width: 1068px) {
  .navigation-popup{
    left: 29vw;
  }
}

@media screen and (min-width: 1200px) {
  .navigation-popup{
    left: 28.5vw;
  }
}

@media screen and (min-width:1500px){
  .navigation-popup{
    left: 31vw;
  }
}

@media screen and (min-width: 1800px) {
  .navigation-popup{
    left: 30.3vw;
  }
}
</style>
