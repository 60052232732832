<template>
  <div class="mobile-menu safari-only">
    <!-- Only show all menu items when products menu item is closed-->
    <div
      v-if="!$store.state.showMobileProductsMenu"
      class="menu-items"
    >
      <ul
        v-for="item in menuItems"
        :key="item"
        class="item"
      >
        <router-link
          :to="`/${$store.state.lang}${item.route}`"
          class="item"
        >
          <li
            @click="menuItemClicked(item)"
          >
            {{ $t(`General.${item.title}`, {}, { locale: $store.state.lang }) }}
          </li>
        </router-link>
      </ul>
      <a
        class="support-menu-item"
        :href="$t('General.SupportURL', {}, { locale: $store.state.lang })"
        target="_blank"
      >{{ $t('General.Support', {}, { locale: $store.state.lang }) }}
      </a>
    </div>

    <!-- Only show products menu item and hide the other menu items when products menu item is open-->
    <div
      v-if="$store.state.showMobileProductsMenu"
      class="product-menu-item-container"
    >
      <!-- List with only 1 item, the products menu item -->

      <ul>
        <li @click="menuItemClicked(menuItems[0])">
          <font-awesome-icon
            :icon="['fas', 'caret-left']"
            class="arrow-back-icon"
          />  {{ $t(`General.${ menuItems[0].title}`, {}, { locale: $store.state.lang }) }}
        </li>
      </ul>
      <span class="title">
        {{ $t(`General.${title}`, {}, { locale: $store.state.lang }) }}
      </span>

      <!-- List with all items that are in the products menu item -->
      <div class="product-items-section">
        <ul
          v-for="item in productMenuItemsFirstSection"
          :key="item.title"
        >
          <router-link
            v-if="item.readyforsale"
            :to="`/${$store.state.lang}/product/${item.route}`"
            class="item"
          >
            <li @click="menuItemClicked(item)">
              {{ $t(`General.${item.title}`, {}, { locale: $store.state.lang }) }}
            </li>
          </router-link>
          <a
            v-else-if="item.linktosite"
            href="https://panieksignalering.nl/"
            target="_blank"
          >
            <li @click="menuItemClicked(item)">
              {{ $t(`General.${item.title}`, {}, { locale: $store.state.lang }) }}
            </li>
          </a>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MobileMenu',
  data () {
    return {
      menuItems: [...this.$store.state.menuItems],
      productMenuItemsFirstSection: this.$store.state.productMenuItems.firstSection,
      title: 'OurProducts'
    }
  },
  methods: {
    menuItemClicked (item) {
      this.$emit('menu-item-clicked', item)
    }
  }
}
</script>

<style lang="scss" scoped>
.mobile-menu {
  position: fixed;
  left: 0;
  top: 50px;
  background-color: #FFFFFF;
  height: 90vh;
  width: 100vw;
  border-top: 3px solid #00000029;
  color: #606060;
  font-size: 18px;
  .menu-items {
    .item {
      text-decoration: none;
      color: #606060;
    }
    .support-menu-item {
      display: block;
      text-decoration: none;
      padding: 1em 0 1em 2em;
      border-bottom: 1px solid #00000029;
      color: #606060;
    }
  }

  ul {
     margin: 0; /* To remove default bottom margin */
     padding: 0; /* To remove default left padding */
     list-style: none;

     li {
         border-bottom: 1px solid #00000029;
         padding: 1em 0 1em 2em;
     }
  }

.product-menu-item-container {
  .arrow-back-icon {
    position: absolute;
    top:0.55em;
    left:0.3em;
    font-size: 28px;
    cursor: pointer;
  }
    .title {
      padding: 0.7em 0 0.2em 2em;
      display: block; // because it's a span
      font-weight: bold;
  }

  .product-items-section {
    // li of product items should have another styling
    padding-bottom: 0.8em;
    ul {
      li {
      border-bottom: 0;
      padding: 0.5em 0 0.3em 2em;
      }
    a {
      color: #606060;
      text-decoration: none;
      }
    }

    .item {
      text-decoration: none;
      color: #606060;
    }
  }

  .bottom-items-section {
    background-color: #F2F2F2;
    height: 100vh;
    padding-top:0.5em;
    li {
     border-bottom: 0;
     padding: 0.5em 0 0.3em 2em;
    }

    .item {
      text-decoration: none;
      color: #606060;
    }
  }
 }
}

// From a max height of 600px change the bottom items section height to let scrollbar show correctly
@media screen and (max-height: 860px) {
    .mobile-menu {
      overflow: auto;
    .product-menu-item-container {
      .bottom-items-section {
          height: 300px;
      }
    }
  }
}

// Code for landscape view, this is different because of the browser showing up on a mobile device
@media screen and (max-height: 600px) and (orientation: landscape) {
  .mobile-menu {
    height: 65vh
  }
}
</style>
