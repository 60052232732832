<template>
  <div class="container">
    <div class="youtube-section">
      <div class="youtube-content">
        <div class="youtube-video">
          <embed
            width="532"
            height="300"
            frameborder="0"
            :src="$t('General.YoutubeURL', {}, { locale: $store.state.lang })"
          >
        </div>
      </div>
      <div class="advantages">
        <div class="heading-text">
          <div class="heading-logo">
            <div class="heading">
              <Heading
                :text="title"
                :primary-color="'#606060'"
              />
            </div>
            <div class="product-logo">
              <img
                src="../../assets/images/Leave-Logo.webp"
                alt="Vrijedagen Logo"
              >
            </div>
          </div>
          <div class="paragraph">
            <slot name="advantages" />
          </div>
        </div>
        <div class="buttons-main">
          <CompeteButton
            :text="$t('General.RequestDemo', {}, { locale: $store.state.lang })"
            :background-color="'#1DAAE1'"
            :variation="'squareCorners'"
            class="first-btn"
            @btn-clicked="$emit('contact-btn-clicked')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Heading from '@/components/Heading/Heading'
import CompeteButton from '@/components/CompeteButton'

export default {
  name: 'MoreEfficient',
  components: {
    Heading, CompeteButton
  },
  props: {
    url: {
      type: String,
      default: 'https://www.youtube.com/embed/Q36y8yTBuCg'
    },
    primaryColor: {
      type: String,
      default: '#1DAAE1'
    },
    title: {
      type: String,
      default: 'Title'
    },
    btnColor: {
      type: String,
      default: '#1DAAE1'
    }
  }
}
</script>

<style lang="scss" scoped>
.youtube-section {
    display: grid;
    grid-template-areas: '. youtube-content advantages .';
    grid-template-columns: 1.8fr minmax(0, 3.2fr) 3.2fr 1.8fr;
    grid-template-rows: auto;
    margin-bottom: 2em;
  .youtube-content {
      grid-area: youtube-content;
      margin-right: 1em;
    .youtube-video {
      grid-area: youtube-video;
      margin-top: 2em;
      position: relative;
      width: 100%;
      padding-bottom: 56.25%;
      height: 0;
      margin-bottom:1.5em;
      overflow:hidden;
      padding-top:0em;
      padding-left:0em;
      embed {
        position: absolute;
        top:0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      align-self: center; // vertical alignment
    }
  }
  .advantages {
    grid-area: advantages;
    margin-left: 1em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .main-image {
      align-self: end; // vertical alignment
      justify-self: end;  // horizontal alignment
      padding-left:1em;
      img {
        display: block; // this is to remove the extra white space underneath an image
        width: 100%;
      }
    }
    .buttons-main {
      padding-top:1em;
      height: 3em;
      .first-btn {
        float:left;
      }
      .second-btn {
        float: right;
        margin-left: 0.5em;
      }
    }
    .heading-logo {
      position: relative;
      .product-logo {
        position: absolute;
        right: 0;
        top: 0;
        img {
          height: 3.5em;
        }
      }
    }
  }
}
@media screen and (min-width: 2300px) {
  .container {
    display: flex;
    justify-content: center;
    .youtube-section {
      min-height: 28vh;
      .heading {
        align-self: end; // vertical alignment
      }
      max-width: 2300px;
    }
  }
}
@media screen and (max-width: 1500px) {
  .youtube-section {
    grid-template-columns: 0.8fr 4.2fr 4.2fr 0.8fr;
  }
}

// From a width of 1066px show the ipad variant
@media screen and (max-width: 1066px) {
  .youtube-section {
    grid-template-columns: 0.3fr 4.7fr 4.7fr 0.3fr;
    .youtube-content{
      .youtube-video {
        position: relative;
        width: 100%;
        padding-bottom: 56.25%;
        height: 0;
        margin-bottom:1.5em;
        overflow:hidden;
        padding-top:0em;
        padding-left:0em;
        embed {
          position: absolute;
          top:0;
          left: 0;
          width: 100%;
          height: 100%;
        }
        align-self: center; // vertical alignment
      }
    }
    .advantages {
      .heading-logo {
        .product-logo {
          img {
            height: 3em;
          }
        }
      }
    }
  }
}

// From a width of 740px show the mobile variant
@media screen and (max-width: 740px) {
  .youtube-section {
      grid-template-areas: '. youtube-content .'
                           '. advantages .';
      grid-template-columns: 0.3fr 9.4fr 0.3fr;
    .advantages {
      margin-left: 0;
    }
    .youtube-content{
      margin-right: 0;
      .youtube-video {
        position: relative;
        width: 100%;
        padding-bottom: 56.25%;
        height: 0;
        margin-bottom:1.5em;
        overflow:hidden;
        padding-top:0em;
        padding-left:0em;
        embed {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
