<template>
  <div class="container">
    <div class="heading">
      <Heading
        :text="title"
        :primary-color="primaryColor"
      />
    </div>
    <div class="card-container">
      <div
        v-for="slide in slides"
        :key="slide.id"
        class="cards"
      >
        <div class="single-card">
          <img
            :src="require(`../../assets/images/${slide.imgUrl}`)"
            :alt="slide.alt"
          >
        </div>
      </div>
    </div>
    <div class="slideshow-container cards-carousel">
      <div>
        <div class="single-card">
          <img
            :src="require(`../../assets/images/${imgUrl}`)"
            :alt="alt"
          >
        </div>
      </div>
      <div
        class="prev"
        @click="minusSlides()"
      >&#10094;</div>
      <div
        class="next"
        @click="plusSlides()"
      >&#10095;</div>
    </div>
  </div>
</template>

<script>
import Heading from '../Heading/Heading.vue'
export default {
  name: 'CustomersSection',
  components: {
    Heading
  },
  props: {
    primaryColor: {
      type: String,
      default: '#1DAAE1'
    },
    title: {
      type: String,
      default: 'Title'
    }
  },
  data () {
    return {
      slideIndex: 0,
      slides: [
        {
          id: 0,
          alt: 'Bluewater logo',
          imgUrl: 'Bluewater.webp'
        },
        {
          id: 1,
          alt: 'Bank of new york mellon logo',
          imgUrl: 'BNY-mellon.webp'
        },
        {
          id: 2,
          alt: 'Suitsupply logo',
          imgUrl: 'Suitsupply.webp'
        },
        {
          id: 3,
          alt: 'Dupont logo',
          imgUrl: 'dupont.webp'
        },
        {
          id: 4,
          alt: 'Honeywell logo',
          imgUrl: 'honeywell.webp'
        },
        {
          id: 5,
          alt: 'Yang Ming logo',
          imgUrl: 'yang-ming-logo.webp'
        },
        {
          id: 6,
          alt: 'Lidl logo',
          imgUrl: 'Lidl-logo.webp'
        },
        {
          id: 7,
          alt: 'GEA Sud logo',
          imgUrl: 'GEA.webp'
        },
        {
          id: 8,
          alt: 'Artsen zonder grenzen logo',
          imgUrl: 'artsen-zonder-grenzen.webp'
        },
        {
          id: 9,
          alt: 'Videojet logo',
          imgUrl: 'videojet.webp'
        }
      ],
      imgUrl: 'artsen-zonder-grenzen.webp',
      alt: 'Artsen zonder grenzen logo'
    }
  },
  mounted () {
    this.showSlides()
  },
  methods: {
    minusSlides () {
      if (this.slideIndex === 0) {
        this.slideIndex = this.slides.length
      }
      this.slideIndex -= 1
      this.showSlides()
    },
    plusSlides () {
      if (this.slideIndex === this.slides.length - 1) {
        this.slideIndex = 0
      } else {
        this.slideIndex += 1
      }
      this.showSlides()
    },
    showSlides () {
      this.imgUrl = this.slides[this.slideIndex].imgUrl
      this.alt = this.slides[this.slideIndex].alt
    }
  }
}

</script>

<style lang="scss" scoped>
.container {
  display: grid;
  grid-template-areas: '. heading .'
                       '. cards .';
  grid-template-columns: 1.8fr 6.4fr 1.8fr;
  grid-template-rows: auto;
  margin-bottom: 2em;
  margin-top: 1em;
}
.heading {
    grid-area: heading;
    text-align: center;
}
.card-container {
  grid-area: cards;
  display: flex;
  flex-wrap: wrap;
  row-gap: 2em;
  justify-content: space-between;
}
.slideshow-container, .cards-carousel {
  display: none;
}
.cards {
  .single-card {
    width: 11vw;
    height: 11vw;
    margin: 10px 0;
    padding: 0 0.5em;
    border: 1px solid lightgray;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
    }
  }
}

@media screen and (max-width: 1500px) {
  .container {
    grid-template-columns: 0.8fr 8.4fr 0.8fr;
    grid-template-rows: auto;
  }
  .cards {
    .single-card {
      width: 13vw;
      height: 13vw;
    }
  }
}

// From a width of 1066px show the ipad variant
@media screen and (max-width: 1066px) {
  .container {
    grid-template-columns: 0.3fr 9.4fr 0.3fr;
  }
  .cards{
    .single-card {
      width: 15vw;
      height: 15vw;
    }
  }
}
@media screen and (max-width: 500px) {
  .cards{
    display: none;
  }
  .cards-carousel {
    grid-area: cards;
    display: flex;
  .single-card {
    width: 75vw;
    height: 75vw;
    margin: 10px 0;
    border: 1px solid lightgray;
    padding: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
    }
  }
}
  * {box-sizing:border-box}

  /* Slideshow container */
  .slideshow-container {
    max-width: 1000px;
    position: relative;
    margin: auto;
  }

  /* Next & previous buttons */
  .prev, .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    margin-top: -22px;
    padding: 16px;
    color: black;
    font-weight: bold;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
  }

  /* Position the "next button" to the right */
  .next {
    right: 0;
    border-radius: 3px 0 0 3px;
  }
}

</style>
