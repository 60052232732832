<template>
  <div class="leave-container">
    <MainSection
      :title="$t('Sick.ProductName', {}, { locale: $store.state.lang })"
      :subheader="$t('Sick.SubHeaderText', {}, { locale: $store.state.lang })"
      :btn-text="$t('General.ContactUsBtn', {}, { locale: $store.state.lang })"
      :sub-heading="true"
      image-url="Sick-Desktop-Banner-Cropped.webp"
      :no-padding-img="true"
      alt-tag-text="LapTopSickSoftwareAltTag"
      @contact-btn-clicked="contactBtnClicked()"
    >
      <template #main-section>
        <p>
          {{ $t('Sick.MainSectionText', {}, { locale: $store.state.lang }) }}
        </p>
      </template>
    </MainSection>
    <AdvantagesSection
      class="sick-mobile"
      :primary-color="'#606060'"
      :title="$t('Sick.Advantages', {}, { locale: $store.state.lang })"
      :background-color="'white'"
      image-url="Sick-Manager.webp"
      logo-url="Sick"
      alt-tag-text="ManLookingAtTabletAltTag"
      alt-tag-logo-text="SickLogoAltTag"
      :try-buy-buttons="true"
      @contact-btn-clicked="contactBtnClicked()"
    >
      <template #main-section>
        <p v-if="activeModule !== 'Timesick'">
          <ul>
            <li>{{ $t('Sick.MainSectionAdvantages', {}, { locale: $store.state.lang }) }}</li>
            <li>{{ $t('Sick.MainSectionAdvantages2', {}, { locale: $store.state.lang }) }}</li>
            <li>{{ $t('Sick.MainSectionAdvantages3', {}, { locale: $store.state.lang }) }}</li>
            <li>{{ $t('Sick.MainSectionAdvantages4', {}, { locale: $store.state.lang }) }}</li>
            <li>{{ $t('Sick.MainSectionAdvantages5', {}, { locale: $store.state.lang }) }}</li>
            <li>{{ $t('Sick.MainSectionAdvantages6', {}, { locale: $store.state.lang }) }}</li>
          </ul>
        </p>
      </template>
    </AdvantagesSection>
    <MainSection
      :show-buttons="false"
      :primary-color="'#606060'"
      :title="$t('Sick.MainSectionTitlePart2', {}, { locale: $store.state.lang })"
      :background-color="'white'"
      image-url="Sick-Cropped.webp"
      alt-tag-text="LaptopCompeteSoftWareSickAltTag"
    >
      <template #main-section>
        <p>
          {{ $t('Sick.MainSectionTextPart2', {}, { locale: $store.state.lang }) }}
        </p>
      </template>
    </MainSection>
    <MainSection
      id="sick-mobile"
      :reversed="true"
      :show-buttons="true"
      :primary-color="'#606060'"
      :background-color="'white'"
      :title="$t('General.MobileAppSectionTitle', {}, { locale: $store.state.lang })"
      image-url="Sick-Mobile-Cropped.webp"
      alt-tag-text="MobileSickSoftwareAltTag"
      :margin="false"
      @contact-btn-clicked="contactBtnClicked()"
    >
      <template #main-section>
        <p>
          {{ $t('Sick.MobileAppSectionText', {}, { locale: $store.state.lang }) }}
        </p>
      </template>
    </MainSection>
    <Footer />
  </div>
</template>

<script>
import Footer from '@/components/Footer/Footer'
import MainSection from '@/components/Sections/MainSection'
import AdvantagesSection from '@/components/Sections/AdvantagesSection'
import { useMeta } from 'vue-meta'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
// todo make importing json dynamic

export default {
  name: 'Sick',
  components: {
    Footer, MainSection, AdvantagesSection
  },
  setup () {
    const store = useStore()

    const { t } = useI18n({
      messages: {
        en: {
          title: 'Sick leave',
          description: 'A leave and sick registration system for €2,- per employee per month. In the SICK module of the Compete HR system you can easily register the sick days of your employees. The manager enters the registration via new sick reports, can enter a partial percentage of being sick and view extensive reports. Naturally, the occupancy rate overview also takes this into account.'
        },
        nl: {
          title: 'Ziektedagen',
          description: 'Een verlof- én ziekregistratiesysteem voor €2,- per medewerker per maand. In de module ZIEK van het Compete HR systeem kunt u eenvoudig de ziektedagen van uw medewerkers registreren. De manager voert de registratie in via nieuwe ziekmeldingen, kan een deelpercentage ziek zijn invoeren en uitgebreide rapportages bekijken. Uiteraard houdt de bezettingsgraad overzicht ook hiermee rekening.'
        }
      }
    })
    useMeta({
      title: t('title', {}, { locale: store.state.lang }),
      description: t('description', {}, { locale: store.state.lang })
    })
  },
  mounted () {
    if (this.$route.name === 'Sick') {
      this.updateChosenProduct('sick')
    } else {
      this.$router.push('/404')
    }
  },
  methods: {
    contactBtnClicked () {
      this.$router.push(`/${this.$store.state.lang}/contact`)
    },
    updateChosenProduct (chosenproduct) {
      // Update store to chosen product
      this.$store.commit('updateActiveProduct', chosenproduct)
    }
  }
}
</script>

<style lang="scss" scoped>
.leave-container {
  white-space: pre-line;
}
@media screen and (max-width: 740px) {
    #sick-mobile {
    margin-bottom: 2em;
  }
}
</style>
