<template>
  <div class="footer-container">
    <div class="footer-cards-section">
      <div class="card-row">
        <div
          v-for="item in footerData"
          :key="item.id"
        >
          <CardFooter
            :title="item.title"
            :text="item.text"
            :list="item.list"
            :variation="item.variation"
            class="col"
          />
        </div>
      </div>
    </div>
    <div class="footer-text-container">
      <div class="footer-text-section">
        <div class="copyright-text">
          {{ $t('General.CopyrightText', {}, { locale: $store.state.lang }) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardFooter from '@/components/Footer/CardFooter'
export default {
  name: 'Footer',
  components: {
    CardFooter
  },
  data () {
    return {
      footerData: [{ id: 0, text: 'FooterTextCard1', variation: 'text' },
        { id: 1, list: ['About', 'TermsAndConditions'], variation: 'clickable-list' },
        { id: 2, variation: 'list' }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.footer-container {
      background-color: #035A9A;
      padding-top: 0.6em;
  .footer-cards-section {
      display: grid;
      grid-template-areas: '. footercards .';
      grid-template-columns: 1.8fr 6.4fr 1.8fr;
      grid-template-rows: auto;
      .card-row {
      grid-area: footercards;
      display: flex;
      justify-content: space-between;
      .col {
      padding-top: 0.6em;
      max-width: 250px;
      color: #FFFFFF;
      padding-bottom: 1.2em; // 1.2 because footer has its own 0.6 padding top
      }
    }
  }
}
.footer-text-container {
    background-color: #114469;
    color: #FFFFFF;
    font-size: 12px;
    .footer-text-section {
        display: grid;
        grid-template-areas: '. copyright .';
        grid-template-columns: 1.8fr 6.4fr 1.8fr;
        grid-template-rows: 38px;
        .copyright-text {
            grid-area: copyright;
            align-self: center;
        }
    }
}

// Code to make component responsive for screens with a width bigger than 2300px
@media screen and (min-width: 2300px) {
  .footer-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .footer-cards-section {
      min-height: 10vh;
       width: 2300px;
      .card-row {
        align-self: center; // vertical alignment
      }
    }
  }
    .footer-text-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    .footer-text-section {
      width: 2300px;
    }
  }
}
@media screen and (max-width: 1500px) {
  .footer-container {
    .footer-cards-section {
      grid-template-columns: 0.8fr 8.4fr 0.8fr;
        grid-template-rows: auto;
      }
  }
     .footer-text-container {
      .footer-text-section {
        grid-template-columns: 0.8fr 8.4fr 0.8fr;
        grid-template-rows: 38px;
    }
   }
}

// From a width of 1066px show the ipad variant
@media screen and (max-width: 1066px) {
  .footer-container {
    .footer-cards-section {
      grid-template-columns: 0.3fr 9.4fr 0.3fr;
      padding-bottom:0.8em;
      .card-row {
        row-gap:20px;
      }
    }
  }
     .footer-text-container {
      .footer-text-section {
       grid-template-columns: 0.3fr 9.4fr 0.3fr;
    }
  }
}
@media screen and (max-width: 653px) {
  .footer-container {
    .footer-cards-section {
      .card-row {
        grid-area: footercards;
        display: flex;
        flex-direction: column;
      }
    }
  }
}
</style>
