<template>
  <div id="pricing-cards-outer-container">
    <div class="pricing-cards-container">
      <PricingCard
        :items="items"
      />
    </div>
  </div>
</template>

<script>
import PricingCard from '@/components/Cards/PricingCard'

export default {
  name: 'PricingCardSection',
  components: { PricingCard },
  props: {
    items: {
      type: Array,
      default: () => []
    }
  }

}
</script>

<style lang="scss" scoped>
#pricing-cards-outer-container {
  // background-color: lightblue;
  display: grid;
  grid-template-areas: '. cards .';
  grid-template-columns: 1.8fr 6.4fr 1.8fr;
  min-height: calc(100vh - 75px - 11rem);

}

.pricing-cards-container {
  grid-area: cards;
  margin: 60px 0px;
  // background-color: green;
  // margin: auto;
}

@media screen  and (max-width: 1500px){
  #pricing-cards-outer-container {
    grid-template-columns: 0.8fr 8.4fr 0.8fr;
  }
}

@media screen and (max-width: 1066px) {
  #pricing-cards-outer-container  {
    grid-template-columns: 0.3fr 9.4fr 0.3fr;
  }
}
@media screen and (max-width: 1154px) {
  .pricing-cards-container {
    margin: 17px auto;
 }
}

</style>
