<template>
  <div class="leave-container">
    <MainSection
      :title="$t('Time.ProductName', {}, { locale: $store.state.lang })"
      :subheader="$t('Time.SubHeaderText', {}, { locale: $store.state.lang })"
      :btn-text="$t('General.ContactUsBtn', {}, { locale: $store.state.lang })"
      :sub-heading="true"
      image-url="Time-Desktop-Banner-Cropped.webp"
      :no-padding-img="true"
      alt-tag-text="LapTopTimeSoftwareAltTag"
      @contact-btn-clicked="contactBtnClicked()"
    >
      <template #main-section>
        <p>
          {{ $t('Time.MainSectionText', {}, { locale: $store.state.lang }) }}
        </p>
      </template>
    </MainSection>
    <AdvantagesSection
      :primary-color="'#606060'"
      :title="$t('Time.Advantages', {}, { locale: $store.state.lang })"
      :background-color="'white'"
      image-url="Manager.webp"
      logo-url="Time"
      alt-tag-text="ManLookingAtWatchAltTag"
      alt-tag-logo-text="TimeLogoAltTag"
      :try-buy-buttons="activeModule === 'Time' ? false : true"
      @contact-btn-clicked="contactBtnClicked()"
    >
      <template #main-section>
        <p>
          <ul>
            <li>{{ $t('Time.MainSectionAdvantages', {}, { locale: $store.state.lang }) }}</li>
            <li>{{ $t('Time.MainSectionAdvantages2', {}, { locale: $store.state.lang }) }}</li>
            <li>{{ $t('Time.MainSectionAdvantages3', {}, { locale: $store.state.lang }) }}</li>
            <li>{{ $t('Time.MainSectionAdvantages4', {}, { locale: $store.state.lang }) }}</li>
            <li>{{ $t('Time.MainSectionAdvantages5', {}, { locale: $store.state.lang }) }}</li>
            <li>{{ $t('Time.MainSectionAdvantages6', {}, { locale: $store.state.lang }) }}</li>
          </ul>
        </p>
      </template>
    </AdvantagesSection>
    <MainSection
      :show-buttons="false"
      :primary-color="'#606060'"
      :title="$t('Time.MainSectionTitlePart2', {}, { locale: $store.state.lang })"
      :background-color="'white'"
      image-url="Time-Desktop-Cropped.webp"
      alt-tag-text="LaptopCompeteSoftWareCalendarAltTag"
    >
      <template #main-section>
        <p>
          {{ $t('Time.MainSectionTextPart2', {}, { locale: $store.state.lang }) }}
        </p>
      </template>
    </MainSection>
    <MainSection
      :reversed="true"
      :show-buttons="true"
      :primary-color="'#606060'"
      :background-color="'white'"
      :title="$t('General.MobileAppSectionTitle', {}, { locale: $store.state.lang })"
      image-url="Time-Mobile-Cropped.webp"
      alt-tag-text="MobileTimeSoftwareAltTag"
      :margin="false"
      @contact-btn-clicked="contactBtnClicked()"
    >
      <template #main-section>
        <p>
          {{ $t('Time.MobileAppSectionText', {}, { locale: $store.state.lang }) }}
        </p>
      </template>
    </MainSection>
    <MainSection
      :show-buttons="false"
      :primary-color="'#606060'"
      :title="$t('General.DifferentLanguages', {}, { locale: $store.state.lang })"
      :margin="false"
      image-url="Languages-Cropped.webp"
      alt-tag-text="MobileLanguagesAltTag"

    >
      <template #main-section>
        <p>
          {{ $t('General.DifferentLanguageText', {}, { locale: $store.state.lang }) }}
        </p>
      </template>
    </MainSection>
    <MainSection
      :reversed="true"
      :show-buttons="false"
      :try-buy-buttons="true"
      :double-buttons="true"
      :primary-color="'#606060'"
      :margin="false"
      :title="$t('General.GiveUpOvertime', {}, { locale: $store.state.lang })"
      image-url="Overtime.webp"
      alt-tag-text="LaptopOverTimeClockAltTag"
    >
      <template #main-section>
        <p>
          {{ $t('Time.MainSectionTextPart3', {}, { locale: $store.state.lang }) }}
        </p>
      </template>
    </MainSection>
    <Footer />
  </div>
</template>

<script>
import Footer from '@/components/Footer/Footer'
import MainSection from '@/components/Sections/MainSection'
import AdvantagesSection from '@/components/Sections/AdvantagesSection'
import { useMeta } from 'vue-meta'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

// todo make importing json dynamic

export default {
  name: 'Time',
  components: {
    Footer, MainSection, AdvantagesSection
  },
  setup () {
    const store = useStore()

    const { t } = useI18n({
      messages: {
        en: {
          title: 'Time registration',
          description: 'A leave and time registration system for €3,50 per employee per month. In the TIME application of Compete HR you can easily register the working hours of your employees. Because employees can submit their times themselves in the web application, an app or via a time terminal, there is always a comprehensive overview of working hours.'
        },
        nl: {
          title: 'Tijdregistratie',
          description: 'Een verlof- én tijdregistratiesysteem voor €3,50 per medewerker per maand. In de TIJD toepassing van Compete HR kunt u eenvoudig de werkuren van uw medewerkers registreren. Doordat medewerkers zelf hun tijden in de webapplicatie, een app of via een tijdterminal kunnen indienen is er altijd een sluitend werkuren overzicht.'
        }
      }
    })
    useMeta({
      title: t('title', {}, { locale: store.state.lang }),
      description: t('description', {}, { locale: store.state.lang })
    })
  },
  mounted () {
    if (this.$route.name === 'Time') {
      this.updateChosenProduct('time')
    } else {
      this.$router.push('/404')
    }
  },
  methods: {
    contactBtnClicked () {
      this.$router.push(`/${this.$store.state.lang}/contact`)
    },
    updateChosenProduct (chosenproduct) {
      // Update store to chosen product
      this.$store.commit('updateActiveProduct', chosenproduct)
    }
  }
}
</script>

<style lang="scss" scoped>
.leave-container {
  white-space: pre-line;
}
</style>
