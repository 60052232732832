<template>
  <div>
    <div class="sales-text">
      <h1>{{ $t(`Pricing.ChooseSubscription`, {}, { locale: $store.state.lang }) }}</h1>
      <p>{{ $t(`Pricing.SalesTextFirstPart`, {}, { locale: $store.state.lang }) }} <span class="second-alinea">{{ $t(`Pricing.SalesTextSecondPart`, {}, { locale: $store.state.lang }) }}</span></p>
    </div>
    <div class="pricing-cards">
      <div
        v-for="item in items"
        :key="item.id"
        class="pricing-card"
        :style="[item.mostchosen ? {'margin-top': '2.7em'} : '']"
      >
        <div
          v-if="item.mostchosen"
          class="most-chosen"
        >
          <img
            src="@/assets/images/strikje-transparant.webp"
            alt="Brandname Image"
          >
          <span
            class="most-chosen-text"
            :style="[$store.state.lang == 'en' ? {'top': '60px', 'left': '47px'} : '']"
          >{{ $t(`Pricing.MostChosen`, {}, { locale: $store.state.lang }) }}</span>
        </div>
        <div
          class="card-title"
          :style="[item.mostchosen ? {'background-color': '#1DAAE1'} : '']"
        >
          {{ $t(`Pricing.${item.title}`, {}, { locale: $store.state.lang }) }}
        </div>
        <div
          class="subtitle"
        >
          <span
            class="item"
          >{{ $t(`Pricing.${item.subtitle}`, {}, { locale: $store.state.lang }) }}</span>
        </div>
        <hr>
        <div class="price-container">
          <span
            class="price"
            :style="[item.mostchosen ? {'color': '#1DAAE1'} : {'color': '#035A9A'}]"
          ><span class="euro-sign">&euro;</span>{{ item.price }}</span>
          <span class="per-user">{{ $t(`Pricing.PerUserMonth`, {}, { locale: $store.state.lang }) }}</span> <!-- todo: vertalen-->
          <CompeteButton
            :text="$t('Pricing.TryNow', {}, { locale: $store.state.lang })"
            background-color="#F56147"
            :variation="'pricing'"
            class="buy-now-btn"
            @click="tryNowClicked(item.title)"
          />
        </div>
        <div class="list-first-part">
          <ul>
            <li
              v-for="listitem in item.listfirstpart"
              :key="listitem"
            >
              <div class="list-item-text">
                {{ $t(`Pricing.${listitem}`, {}, { locale: $store.state.lang }) }}
              </div>
              <font-awesome-icon
                :icon="['fas', 'check']"
                class="check-icon"
              />
            </li>
          </ul>
        </div>
        <Transition name="slide-fade">
          <div
            v-show="showArrowUp[item.id]"
            class="list-second-part"
          >
            <!-- Toon hier de rest van de data! -->
            <ul>
              <li
                v-for="listitem in item.listsecondpart"
                :key="listitem"
              >
                <div class="list-item-text">
                  {{ $t(`Pricing.${listitem}`, {}, { locale: $store.state.lang }) }}
                </div>
                <font-awesome-icon
                  :icon="['fas', 'check']"
                  class="check-icon"
                />
              </li>
            </ul>
          </div>
        </Transition>
        <div
          class="angle-down-icon"
        >
          <font-awesome-icon
            :icon="['fa', 'angle-down']"
            @click="toggleArrowIcon(item.id)"
          />
        </div>
        <div
          class="angle-up-icon"
        >
          <font-awesome-icon
            :icon="['fa', 'angle-up']"
            @click="toggleArrowIcon(item.id)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CompeteButton from '@/components/CompeteButton'
export default {
  name: 'PricingCard',
  components: { CompeteButton },
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      showArrowUp: [false, false]
    }
  },
  methods: {
    tryNowClicked (subscription) {
      const defaultmessage = this.$t('General.DefaultMessageContact', {}, { locale: this.$store.state.lang }) + subscription + '.'
      this.$store.commit('update_message', defaultmessage)

      this.$router.push('/' + this.$store.state.lang + '/contact')
    },
    toggleArrowIcon (cardid) {
      // Get arrow elements to distinguish between the arrows of the two cards
      const arrowDownElements = document.getElementsByClassName('angle-down-icon')
      const arrowUpElements = document.getElementsByClassName('angle-up-icon')

      // Use array to distinguish between the two cards
      this.showArrowUp[cardid] = !this.showArrowUp[cardid]

      if (this.showArrowUp[cardid]) {
        arrowDownElements[cardid].style.display = 'none'
        arrowUpElements[cardid].style.display = 'flex'
      } else {
        arrowDownElements[cardid].style.display = 'flex'
        arrowUpElements[cardid].style.display = 'none'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.pricing-cards {
  display: flex;
  justify-content: end;
  column-gap: 40px;
  align-items: flex-start;
  padding-top: 1em;
}

.pricing-card{
  width: 318px;
  min-height: 626px;
  background-color: #FFFFFF;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 15px;
}

.most-chosen {
  position: absolute;
  margin-left:205px;
  margin-top:-37px;
}
.most-chosen-text {
  position: absolute;
  top: 59px;
  left: 40px;
  transform: rotate(45deg);
  color: #FFFFFF;
  font-size: 13px;
  font-weight: bold;
  // background-color: green;
}

.sales-text {
  position: absolute;
  max-width: 18vw;
  padding-right:1em;
  padding-top: 8em;
}

.sales-text h1 {
  min-width: 19vw;
}

.second-alinea {
  display: block;
  padding-top: 0.6em;
}

.card-title {
  background-color: #035A9A;
  color: #FFFFFF;
  height: 76px;
  border-radius: 15px 15px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
}

.subtitle {
  display: flex;
  font-size: 18px;
  padding-top: 1em;
  justify-content: center;
}

.subtitle .check-icon {
  color: #035A9A;
  padding-right: 0.1em;
}

.subtitle .item {
  padding-bottom: 0.3em;
}

hr{
  width: 282px;
}

.price-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 150px;
  margin-top: 1.1em;
}
.price {
  font-size: 48px;
}

.euro-sign {
  font-size:24px;
  position: relative;
  bottom: 7px;
  right: 3px;
}
.per-user {
  font-size: 16px;
  margin-top: -0.3em;
  margin-bottom: 1em;
}

.buy-now-btn {
  width: 244px;
}

.list-first-part ul {
  list-style: none;
  padding:0;
  margin-top: 1.5em;
  margin-bottom: 0.5em;
  font-size: 16px;
}

.list-first-part ul li{
  padding-bottom: 0.7em;
  padding-top:0.7em;
  padding-left: 1em;
  border-top: 1px solid #000000;
}

.list-first-part ul li .list-item-text {
  max-width: 275px;
  display: inline-block;
}

.list-first-part ul li .check-icon{
  color: #035A9A;
  float: right;
  margin-right: 0.4em;
  margin-top:0.25em;
}

.list-second-part ul {
  list-style: none;
  padding:0;
  margin-top: -0.4em;
  margin-bottom: 0.5em;
  font-size: 16px;
}

.list-second-part ul li{
  padding-bottom: 0.7em;
  padding-top:0.7em;
  padding-left: 1em;
  border-top: 1px solid #000000;
}

.list-second-part ul li .list-item-text {
  max-width: 275px;
  display: inline-block;
}

.list-second-part ul li .check-icon{
  color: #035A9A;
  float: right;
  margin-right: 0.4em;
  margin-top:0.25em;
}

.slide-fade-enter-active {
  transition: all 0.5s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.5s ease-in;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(-30px);
  opacity: 0;
}

.angle-down-icon {
  display: flex;
  justify-content: center;
  font-size: 48px;
  padding-bottom: 0.1em;
}

.angle-up-icon {
  display: flex;
  justify-content: center;
  font-size: 48px;
  display: none;
  padding-bottom: 0.1em;
}

.angle-down-icon .fa-angle-down{
  cursor: pointer;
}

.angle-up-icon .fa-angle-up{
  cursor: pointer;
}

@media screen and (max-width: 1154px) {

  .pricing-cards {
    width: 318px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 3.2em;
  }

  /* So the most chosen card has the same margin as the other one */
  .pricing-card {
    margin-top: 0em !important;
  }

  .pricing-card:nth-child(2) {
    margin-bottom: 2em;
  }

  .sales-text {
    position: relative;
    max-width: 315px;
    padding-bottom:0.3em;
    padding-top: 0em;
    padding-right:0em;
    padding-left:0.7em;
  }

  .sales-text h1 {
    min-width: 50vw;
  }

}
</style>
