<template>
  <div class="container">
    <MainSection
      :title="$t('General.About', {}, { locale: $store.state.lang })"
      :btn-text="$t('General.ViewOurProducts', {}, { locale: $store.state.lang })"
      :primary-color="'#1DAAE1'"
      :hide-button="true"
    >
      <template #main-section>
        <p>
          {{ $t('General.AboutPageMainTextPart', {}, { locale: $store.state.lang }) }}
        </p>
      </template>
    </MainSection>
    <MainSection
      :title="$t('General.QualitySecurity', {}, { locale: $store.state.lang })"
      :primary-color="'#1DAAE1'"
      :reversed="true"
      :show-buttons="false"
      :background-color="'#FFFFFF'"
      image-url="Our-Software-Cropped.webp"
    >
      <template #main-section>
        <p>
          {{ $t('General.AboutPageMainTextPart2', {}, { locale: $store.state.lang }) }}
        </p>
      </template>
    </MainSection>
    <StrongByService
      :service-items="serviceItems"
      title="AlsoInteresting"
    />
    <Footer />
  </div>
</template>

<script>
import MainSection from '@/components/Sections/MainSection'
import StrongByService from '@/components/Sections/StrongByService'
import Footer from '@/components/Footer/Footer'
import { useMeta } from 'vue-meta'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

export default {
  name: 'About',
  components: {
    MainSection, StrongByService, Footer
  },
  setup () {
    const store = useStore()

    const { t } = useI18n({
      messages: {
        en: {
          title: 'About Compete HR',
          description: 'It is our mission to be your reliable partner by providing a secure ICT environment with sustainability as its guiding principle.'
        },
        nl: {
          title: 'Over Compete HR',
          description: 'Het is onze missie om uw betrouwbare partner te zijn door een veilige ICT-omgeving te leveren met duurzaamheid als leidraad.'
        }
      }
    })
    useMeta({
      title: t('title', {}, { locale: store.state.lang }),
      description: t('description', {}, { locale: store.state.lang })
    })
  },
  data () {
    return {
      homeMenuItems: ['Producten', 'Support', 'Over ons'],
      serviceItems: [
        { id: 0, title: 'LeaveDays', mainContent: 'AboutUsLeave', primaryColor: '#1DAAE1', linkText: 'ReadMore', linkUrl: `/${this.$store.state.lang}/product/leave` },
        { id: 1, title: 'LeaveTime', mainContent: 'AboutUsTime', primaryColor: '#1DAAE1', linkText: 'ReadMore', linkUrl: `/${this.$store.state.lang}/product/time` },
        { id: 2, title: 'LeaveSick', mainContent: 'AboutUsSick', primaryColor: '#1DAAE1', linkText: 'ReadMore', linkUrl: `/${this.$store.state.lang}/product/sick` }
      ]
    }
  },
  mounted () {
    this.$store.commit('updateActivePage', 'About')
  }
}
</script>

<style lang="scss" scoped>
</style>
