<template>
  <div
    class="main-section"
    :style="{'background-color': backgroundColor}"
  >
    <div
      class="main-content-reversed"
    >
      <div class="heading">
        <Heading
          :text="title"
          :primary-color="primaryColor"
        />
      </div>
      <div class="paragraph">
        <slot name="main-section" />
      </div>
      <div class="main-image">
        <img
          :src="require(`@/assets/images/${imageUrl}`)"
          alt="AccessMainImage"
        >
      </div>
    </div>
  </div>
</template>

<script>
import Heading from '@/components/Heading/Heading'

export default {
  name: 'OurSoftware',
  components: {
    Heading
  },
  props: {
    title: {
      type: String,
      default: 'Title'
    },
    primaryColor: {
      type: String,
      default: '#606060'
    },
    backgroundColor: {
      type: String,
      default: '#F2F2F2'
    },
    imageUrl: {
      type: String,
      default: 'Main.webp'
    }

  }
}
</script>

<style lang="scss" scoped>
  .main-section {
    .main-content-reversed {
      display: grid;
      grid-template-areas: '. main-image heading .'
                           '. main-image paragraph .'
                           '. main-image buttons-main .' ;
      grid-template-columns: 1.8fr 3.2fr 3.2fr 1.8fr;
      grid-template-rows: auto auto auto;
      padding-bottom: 2em;
      .heading {
        grid-area: heading;
        padding-left:1em;
      }
      .paragraph {
        grid-area: paragraph;
        padding-left:1em;
      }
      .main-image {
        grid-area: main-image;
        padding-top:1em;
        width: 100%;
        height: 400px;
        img {
          display: block; // this is to remove the extra white space underneath an image
          width: 100%;
          height: 100%;
          object-fit: cover;
          // this is so the image does't get blurry
          image-rendering: -moz-crisp-edges;         /* Firefox */
          image-rendering:   -o-crisp-edges;         /* Opera */
          image-rendering: -webkit-optimize-contrast;/* Webkit (non-standard naming) */
          image-rendering: crisp-edges;
          -ms-interpolation-mode: nearest-neighbor;  /* IE (non-standard property) */
        }
      }
    }

  }

    @media screen and (min-width: 2300px) {
      .main-section {
        min-height: 28vh;
        display: flex;
        justify-content: center;
        align-items: center;
        .main-content-reversed {
          grid-template-rows: auto auto auto;
          max-width: 2300px;
          .heading {
            align-self: start; // vertical alignment
          }
          .main-image, .paragraph {
            align-self: center; // vertical alignment
            justify-self: start;  // horizontal alignment
          }
        }
      }
    }
@media screen and (max-width: 1500px) {
  .main-section {
    .main-content-reversed {
      grid-template-columns: 0.8fr 4.2fr 4.2fr 0.8fr;
    }
  }
}

// From a width of 1066px show the ipad variant
@media screen and (max-width: 1066px) {
  .main-section {
    .main-content-reversed {
      grid-template-columns: 0.3fr 4.7fr 4.7fr 0.3fr;
      grid-template-rows: auto auto auto;
      .paragraph {
        padding-left: 2em;
      }
      .heading {
        padding-left: 1.7em;
      }
      .main-image{
          height: 330px;
      }
    }
  }
}

// From a width of 740px show the mobile variant
@media screen and (max-width: 740px) {
  .main-section {
    .main-content-reversed {
      grid-template-columns: 0.3fr 9.4fr 0.3fr;
      grid-template-areas: '. main-image .'
                           '. heading .'
                           '. paragraph .'
                           '. buttons-main .' ;
      grid-template-rows: auto auto auto;
      padding-bottom: 0;
      .main-image {
        padding-top:1.5em;
        padding-bottom:0em;
        align-self: center; // vertical alignment
        justify-self: center;  // horizontal alignment
      }
      .heading {
        padding-top:0em;
        padding-left:0;
      }
      .paragraph {
        padding-left:0;
        margin-top: -0.3em;
      }
    }
  }
}
</style>
