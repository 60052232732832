import { createApp } from 'vue/dist/vue.esm-bundler'
import router from '@/router'
import App from './App.vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faPhone, faCheck, faPlus, faLevelDownAlt, faBars, faSearch, faEuroSign, faCalendarAlt, faClock, faFileMedical, faClipboard, faLock, faExclamationTriangle, faPlusSquare, faTimes, faCaretDown, faCaretUp, faCaretLeft, faInfoCircle, faStar, faHandPointRight, faCheckCircle, faArrowTurnDown, faXmark, faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import store from './store'
import ClickOutside from './directives/click-outside'

import { createI18n } from 'vue-i18n'
import en from './locales/en.json'
import nl from './locales/nl.json'
import { createMetaManager } from 'vue-meta'
// i18n configuration
const i18n = createI18n({
  legacy: false,
  locale: 'en',
  globalInjection: true,
  messages: {
    en: en,
    nl: nl
  },
  fallbackLocale: 'en'
})

library.add(faPhone)
library.add(faCheck)
library.add(faPlus)
library.add(faLevelDownAlt)
library.add(faBars)
library.add(faSearch)
library.add(faEuroSign)
library.add(faCalendarAlt)
library.add(faClock)
library.add(faFileMedical)
library.add(faClipboard)
library.add(faLock)
library.add(faExclamationTriangle)
library.add(faPlusSquare)
library.add(faCaretDown)
library.add(faTimes)
library.add(faXmark)
library.add(faCaretLeft)
library.add(faCaretUp)
library.add(faInfoCircle)
library.add(faStar)
library.add(faHandPointRight)
library.add(faCheckCircle)
library.add(faArrowTurnDown)
library.add(faAngleDown)
library.add(faAngleUp)

const app = createApp(App)

app.directive('click-outside', ClickOutside)
app.use(router)
app.use(store)
app.use(i18n)
app.use(createMetaManager())
app.component('FontAwesomeIcon', FontAwesomeIcon)
app.mount('#app')
