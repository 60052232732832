<template>
  <div class="container">
    <div class="review-container">
      <div class="test">
        <a
          href="https://www.google.com/maps/place/Compete+IT+Solutions/@51.9343031,4.4047777,17z/data=!4m7!3m6!1s0x47c4353418d739a9:0xecff4d248fa4ce76!8m2!3d51.9342664!4d4.406954!9m1!1b1?hl=nl"
          target="_blank"
        >
          <div class="stars">
            <div class="base-stars">
              <font-awesome-icon :icon="['fas', 'star']" />
              <font-awesome-icon :icon="['fas', 'star']" />
              <font-awesome-icon :icon="['fas', 'star']" />
              <font-awesome-icon :icon="['fas', 'star']" />
              <font-awesome-icon :icon="['fas', 'star']" />
            </div>
            <div
              class="filled-stars"
              :style="{width: starsWidth+'%'}"
            >
              <font-awesome-icon
                :icon="['fas', 'star']"
                class="icon"
              />
              <font-awesome-icon
                :icon="['fas', 'star']"
                class="icon"
              />
              <font-awesome-icon
                :icon="['fas', 'star']"
                class="icon"
              />
              <font-awesome-icon
                :icon="['fas', 'star']"
                class="icon"
              />
              <font-awesome-icon
                :icon="['fas', 'star']"
                class="icon"
              />
            </div>
          </div>
        </a>
        <div class="score">
          {{ score }}
        </div>
      </div>
      <div class="text">
        {{ $t('General.RatingText', {}, { locale: $store.state.lang }) }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Review',
  data () {
    return {
      starsWidth: '',
      score: '4.9'
    }
  },
  created () {
    this.calculatedWidth()
  },
  methods: {
    calculatedWidth () {
      this.starsWidth = parseFloat(this.score) / 5.2 * 100
    }
  }
}
</script>
<style lang="scss" scoped>
.container {
  background-color: #F2F2F2;
  padding-bottom: 1em;
  .review-container {
    display: grid;
    grid-template-areas: 'stars'
                         'text';
    grid-template-rows: auto;
    .stars {
      position: relative;
      width: 140px;
      height: 30px;
      display: inline-block;
      font-size: 24px;
      .base-stars {
        color: #606060;
        width: 100%;
        height: 100%;
      }
      .filled-stars {
        position: absolute;
        top: 0;
        left: 0;
        overflow: hidden;
        height: 100%;
        color: gold;
        white-space: nowrap;
      }
    }
    .text {
      grid-area: text;
      padding-top: 1em;
      padding-bottom: 2em;
      font-size: 12px;
    }
    .test {
      grid-area: stars;
      display: flex;
    }
    .score {
      margin-left: 0.5em;
      font-size: 24px;
    }
  }
}
@media screen and (min-width:2300px) {
  .container {
    display: flex;
    justify-content: start;
    align-items: center;
  }
}
</style>
