<template>
  <div class="container">
    <div class="strong-by-service-section">
      <div class="header">
        <Heading
          :text="$t(`General.${title}`, {}, { locale: $store.state.lang })"
          :primary-color="'#1DAAE1'"
        />
      </div>
      <div class="cards">
        <CardGrid
          :items="serviceItems"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CardGrid from '@/components/Cards/StrongByServiceCard'
import Heading from '@/components/Heading/Heading'
export default {
  name: 'StrongByService',
  components: {
    CardGrid, Heading
  },
  props: {
    serviceItems: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: 'Title'
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  background-color: #E9F2F5;
}
.strong-by-service-section {
  display: grid;
  grid-template-areas: '. heading .'
                        '. cards .';
  grid-template-columns: 1.8fr 6.4fr 1.8fr;
  grid-template-rows: 100px auto;
  padding-bottom: 4.5em;
  .header {
    grid-area: heading;
    padding-top:0.3em;
    text-align:center;
  }
  .cards {
    grid-area: cards;
  }
}

@media screen  and (max-width: 1500px){
  .strong-by-service-section {
    grid-template-columns: 0.8fr 8.4fr 0.8fr;
  }
}

@media screen and (max-width: 1066px) {
  .strong-by-service-section {
    grid-template-columns: 0.3fr 9.4fr 0.3fr;
    grid-template-rows: 70px auto;
  }
}

@media screen and (min-width: 2300px) {
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 26vh;

    .strong-by-service-section {
      width: 2300px;
    }
  }
}

// From a width of 915px center the cards
@media screen and (max-width: 915px) {
  .strong-by-service-section {
    grid-template-rows: 75px auto;
    .cards {
      justify-self: center;
    }
  }
}

</style>
