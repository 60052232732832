<template>
  <div
    class="navbar-container"
    :style="[$store.state.activePage === 'Order' || $store.state.activePage === 'Contact' || $store.state.activePage === 'Pricing' ? {'box-shadow': 'rgba(0, 0, 0, 0.04) 0px 3px 5px'} : '']"
  >
    <div class="navbar">
      <div class="brand-name">
        <router-link
          :to="`/${$store.state.lang}`"
          class="brand-name-link"
          @click="brandNameClicked()"
        >
          <BrandName />
        </router-link>
      </div>
      <div class="top-menu">
        <TopMenu
          :menu-items="menuItems"
          @menu-item-clicked="menuItemClicked"
        />
      </div>
      <div
        class="hamburger-menu"
        @click="$emit('toggle-mobile-menu')"
      >
        <font-awesome-icon
          v-if="!$store.state.showHamburger"
          :icon="['fas', 'bars']"
        />
        <font-awesome-icon
          v-if="$store.state.showHamburger"
          :icon="['fas', 'xmark']"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TopMenu from '@/components/Header/TopMenu'
import BrandName from '@/components/Header/BrandName'
export default {
  name: 'NavBar',
  components: {
    TopMenu, BrandName
  },
  props: {
    menuItems: {
      type: Array,
      default: () => ['test1', 'test2']
    },
    primaryColor: {
      type: String,
      default: '#114469'
    },
    color: {
      type: String,
      default: '#FFFFFF'
    }
  },
  data () {
    return {
      showCross: false
    }
  },
  computed: {
    CheckIfModuleIsActive () {
      if (this.$route.params.productname) {
        return '/orderdemo/' + this.$route.params.productname
      } else {
        return '/productselector' // if no module is active, direct to product selector page
      }
    }
  },
  methods: {
    menuItemClicked (item) {
      this.$emit('menu-item-clicked', item)
    },
    brandNameClicked () {
      // Close mobile menu when navigating to homepage
      if (this.$store.state.showHamburger) {
        this.$store.commit('closeHandler', 'showHamburger')
        this.$store.commit('closeHandler', 'showMobileProductsMenu')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.navbar-container {
  .navbar {
    display: grid;
    grid-template-areas: '. brandname topmenu buttons .';
    grid-template-columns:1.8fr 1.2fr 3.8fr 1.4fr 1.8fr;
    grid-template-rows: 75px;
    .brand-name {
      grid-area: brandname;
      padding-top:0.4em;
      display: flex;
      align-items: center;
      .brand-name-link{
        cursor: pointer;
      }
    }
    .top-menu {
      grid-area:topmenu;
      display: flex;
      align-items: center;
    }
    .buttons-navbar {
      grid-area:buttons;
      justify-self: end;
      display: flex;
      align-items: center;
      .login-btn {
        float:left;
      }
      .try-btn {
        float: left;
        margin-left: 1.5em;
      }
    }
    .hamburger-menu {
      grid-area: buttons;
      display: none;
      color: #606060;
      font-size: 28px;
      width: 0.87em;
    }
  }
}

@media screen and (min-width: 2300px) {
  .navbar-container {
  display: flex;
  justify-content: center;
    .navbar {
      min-height: 5vh;
      grid-template-rows: auto;
      width: 2300px;
    }
  }
}
@media screen and (max-width: 1500px) {
  .navbar-container {
    .navbar {
    grid-template-columns:0.8fr 1.9fr 5.4fr 1.1fr 0.8fr;
    grid-template-rows: 75px;
    }
  }
}

// From a width of 1066px show the ipad variant
@media screen and (max-width: 1066px) {
  .navbar-container {
    .navbar {
       grid-template-columns:0.3fr 5.5fr 3.6fr 0.3fr 0.3fr;
       grid-template-rows: 50px;
       grid-template-areas: '. brandname topmenu hamburgermenu .';
       .brand-name {
         padding-top: 0.35em;
         padding-left: 0.15em;
       }
       .top-menu {
         justify-self: start;  // horizontal alignment
       }
      .hamburger-menu {
        grid-area: hamburgermenu;
        align-self: center;
        display: inline;
        margin-top: 0.15em;
      }
      .buttons-navbar {
        display: none;
      }
    }
  }
}

</style>
