<template>
  <div>
    <div
      v-if="!showConfirmationMessage && !failedPOSTRequest"
      class="container"
    >
      <h1>{{ $t("General.SendMessage", {}, { locale: $store.state.lang }) }}</h1>
      <div class="user-form">
        <InputField
          v-for="field in fields"
          :key="field.id"
          :field-info="field"
        />
        <div class="label-input">
          <label for="username">{{
            $t("Forms.Message", {}, { locale: $store.state.lang })
          }}</label>
          <div class="message-input">
            <textarea
              id="message"
              v-model="fieldValue"
              type="text"
              name="message"
              @blur="validate()"
            />
            <info-popover
              :info-text="infoTextMessage"
              :error="inViolation"
              class="icon"
            />
          </div>
        </div>
        <div>
          <ReCaptcha />
        </div>
        <CompeteButton
          :text="$t('Forms.Send', {}, { locale: $store.state.lang })"
          :background-color="canClick ? '#1DAAE1' : '#D6D6D6'"
          :variation="'squareCorners'"
          class="start-demo-btn"
          :error-free="canClick"
          @btn-clicked="sendMessage"
        />
      </div>
    </div>
    <div
      v-else-if="failedPOSTRequest"
      class="after-message"
    >
      {{ $t(`Forms.${errorMessageBasedOnStatusCode}`, {}, { locale: $store.state.lang }) }}
    </div>
    <div
      v-else
      class="after-message"
    >
      {{ $t("Forms.ConfirmationMessage", {}, { locale: $store.state.lang }) }}
    </div>
  </div>
</template>

<script>
import CompeteButton from '@/components/CompeteButton'
import InputField from '@/components/FormComponents/MessageInputField'
import InfoPopover from '@/components/FormComponents/InfoPopover.vue'
import ReCaptcha from '@/components/FormComponents/ReCaptcha.vue'
import axios from 'axios'

export default {
  name: 'SendMessage',
  components: {
    CompeteButton,
    InputField,
    InfoPopover,
    ReCaptcha
  },
  data () {
    return {
      fields: [
        {
          id: 'name',
          label: 'Name',
          type: 'text',
          infoText: 'InfoTextName',
          required: true,
          requiredText: 'ErrorTextName',
          regexText: 'ErrorTextName',
          inviolation: true
        },
        {
          id: 'messageEmail',
          label: 'Email',
          type: 'email',
          infoText: 'InfoTextEmail',
          required: true,
          requiredText: 'ErrorTextEmail1',
          regex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
          regexText: 'ErrorTextEmail2',
          inviolation: true
        },
        {
          id: 'messagePhoneNr',
          label: 'PhoneNr',
          type: 'tel',
          infoText: 'InfoTextPhoneNumber',
          required: false,
          inviolation: false
        }
      ],
      infoTextMessage: 'InfoTextMessage',
      requiredText: 'ErrorTextMessage',
      infoText: 'InfoTextMessage',
      messageError: false,
      inViolation: false,
      contactformData: {
        Name: '',
        Email: '',
        Phone: '',
        Message: ''
      },
      showConfirmationMessage: false,
      failedPOSTRequest: false,
      errorMessageBasedOnStatusCode: ''
    }
  },
  computed: {
    canClick () {
      return this.$store.getters.messageFormIsValid
    },
    fieldValue: {
      get () {
        return this.$store.state.message
      },
      set (value) {
        this.$store.commit('update_message', value)
      }
    }
  },
  mounted () {
    if (this.$store.state.messageEmail !== '' && this.$store.state.message !== '' && this.$store.state.name !== '' && this.$store.state.phoneNr !== '' && this.$store.state.checkboxMessage) {
      this.fields.forEach((field) =>
        this.$store.commit('setMessageFormFieldInvalid', {
          inError: false,
          fieldId: field.id
        })
      )
      this.$store.commit('setMessageFormFieldInvalid', { inError: false, fieldId: 'message' })
      this.$store.commit('setMessageFormFieldInvalid', { inError: false, fieldId: 'checkboxMessage' })
    } else {
      this.fields.forEach((field) =>
        this.$store.commit('setMessageFormFieldInvalid', {
          inError: true,
          fieldId: field.id
        })
      )
      this.$store.commit('setMessageFormFieldInvalid', { inError: true, fieldId: 'message' })
      this.$store.commit('setMessageFormFieldInvalid', { inError: true, fieldId: 'checkboxMessage' })
    }
  },
  methods: {
    validate () {
      if (this.fieldValue === undefined || this.fieldValue === '') {
        this.inViolation = true
        this.infoTextMessage = this.requiredText
      } else {
        this.inViolation = false
        this.infoTextMessage = this.infoText
      }
      // emit the value of inviolation to check if the field is errorfree
      this.$store.commit('setMessageFormFieldInvalid', { inError: this.inViolation, fieldId: 'message' })
    },
    async sendMessage () {
      console.log('Handle message sending here')

      this.contactformData.Name = this.$store.state.name
      this.contactformData.Email = this.$store.state.messageEmail
      this.contactformData.Phone = this.$store.state.messagePhoneNr
      this.contactformData.Message = this.$store.state.message

      console.log(this.contactformData)

      // 'Make API call to send the message with sendgrid!'
      // No need to check input validation, because when input is wrong, the button is disabled
      // Real URL: comes soon
      // TEST URL: https://jsonplaceholder.typicode.com/posts

      let statusCodeError = ''
      // 'https://competehr-landingpage-functions.azurewebsites.net/api/SendContactMail'
      // 'https://jsonplaceholder.typicode.com/posts' testURL
      await axios.post('https://competehr-landingpage-functions.azurewebsites.net/api/SendContactMail', this.contactformData)
        .then(response =>
          (response.status === 201 ? console.log(response.status) + console.log('show confirmation content') : ''))
        .catch(error => (statusCodeError = error.response.status) + (this.failedPOSTRequest = true))

      // Provide an appropriate errormessage depending on the status code
      if (statusCodeError === 400) {
        this.errorMessageBasedOnStatusCode = 'ErrorMessagePOST400'
      } else if (statusCodeError === 401) {
        this.errorMessageBasedOnStatusCode = 'ErrorMessagePOST401'
      } else if (statusCodeError === 404) {
        this.errorMessageBasedOnStatusCode = 'ErrorMessagePOST404'
      }

      if (statusCodeError === '') {
        this.showConfirmationMessage = true
      } else {
        this.showConfirmationMessage = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 1.5em;
  h1 {
    font-weight: 400;
    align-self: center;
  }
  .label-input {
    display: flex;
    flex-direction: column;
  }
  .message-input {
    display: flex;
    padding: 0.5em 0 1em;
  }
  .icon {
    position: relative;
    top: 0.2em;
    right: 0.5em;
  }
/* To fix message field issue*/
  .info-popover-container {
    height: 34px;
  }
  .message-input textarea {
    width: 100%;
    height: 90px;
    border: none;
    -webkit-appearance: none;
    box-shadow: -2px 2px 2px #00000029;
    font-size: 16px;
    padding-left: 0.6em;
    margin-right: 1em;
    resize: none;
    font-family: inherit;
    color: #606060;
  }
  .message-input textarea:focus {
    outline: none;
  }
  .start-demo-btn {
    float: right;
  }
  .after-message{
    // background-color: red;
    margin-top:5.5em;
    margin-left:1em;
  }
  @media screen and (max-width: 1066px) {
    .message-input textarea {
      margin-right: 0.3em;
    }
    .start-demo-btn {
      padding-right: 2.2em;
    }
    .icon {
      right: 0.1em;
    }
  }
    @media screen and (max-width: 740px) {
    .after-message{
      margin-top:0em;
      margin-left:0em;
      margin-bottom:1em;
    }
  }
}
</style>
