<template>
  <div
    v-click-outside="closeLanguages"
    class="language-switcher"
  >
    <div
      class="header"
      :style="[$store.state.showLanguages ? {'border-bottom': '3px solid #1DAAE1'} : '']"
      @click="toggleDropDown"
    >
      <span class="language-text" style="text-transform:uppercase;">{{ $store.state.lang }}</span>
    </div>
    <div
      v-if="$store.state.showLanguages"
      class="language-options"
    >
      <span class="select-language-text"> {{ $t('General.selectLanguageText', {}, { locale: $store.state.lang }) }} </span>
      <ul>
        <li
          v-for="item in languages"
          :key="item.id"
          :style="[item.abbreviation === $store.state.lang.toUpperCase() ? {'cursor': 'auto'} : '' ]"
          @click="updateLanguage(item)"
        >
          <span
            class="language-text"
            :style="[item.abbreviation === $store.state.lang.toUpperCase() ? {'color': '#D6D6D6'} : {'color': '#606060'} ]"
          >{{ item.abbreviation }} - {{ item.title }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LanguageSwitcher',
  data () {
    return {
      languages: [{ id: 0, title: 'Nederlands', abbreviation: 'NL' },
        { id: 1, title: 'English', abbreviation: 'EN' }],
      chosenLanguage: 'NL',
      selectLanguageText: this.$t('General.ShowWebsiteIn', {}, { locale: this.$store.state.lang })
    }
  },
  methods: {
    toggleDropDown () {
      this.$store.commit('toggleHandler', 'showLanguages')
    },
    async updateLanguage (language) {
      this.chosenLanguage = language.abbreviation
      this.$store.commit('updateLanguage', this.chosenLanguage.toLowerCase())
      this.toggleDropDown()
      this.changeUrl()
    },
    closeLanguages () {
      this.$store.commit('closeHandler', 'showLanguages')
    },
    changeUrl () {
      const path = this.$route.fullPath.split('/').slice(2).join('/')
      path ? this.$router.push(`/${this.$store.state.lang}/${path}`).then(() => window.location.reload()) : this.$router.push(`/${this.$store.state.lang}`).then(() => window.location.reload())
    }
  }
}
</script>

<style  lang="scss" scoped>
.language-switcher {
  position: relative;
  .header {
  cursor: pointer;
  width: 21px;
  height:21px;
  text-align: center;
  color: #D6D6D6;
  }
  .language-options {
   position: absolute;
   width: 286px;
   top:3em;
   left: -1.4em;
   background-color: #FFFFFF;
   box-shadow: #00000024 0px 25px 50px -12px;
  .select-language-text {
    color: #606060;
    font-weight: bold;
    display: block;
    padding-left: 1.45em;
    padding-top: 1.3em;
    padding-bottom: 0.9em;
  }
   ul{
     list-style: none;
     padding: 0;
     margin: 0;
     li{
      padding-left:1.05em;
      height: 50px;
      line-height: 56px; // to vertically center the li in the ul
     }
     li:hover {
       background-color: #bdecff;
       cursor: pointer;
     }
   }
  .language-text {
    position: relative;
    bottom: 0.2em;
    left: 0.4em;
    }
  }
}

@media screen and (max-width: 1066px) {
  .language-switcher {
    .header {
        width: 21px;
        height:21px;
    }
    .language-options {
      top:1.75em;
      position: absolute;
      left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%)
     }
  }
}

// From a width of 430px show the mobile variant
@media screen and (max-width: 430px) {
   .language-switcher {
      .language-options {
       left: -14.5vw;
     }
   }
}

// In landscape view, give a fixed height to make the list scrollable
@media screen and (max-height: 600px) and (orientation: landscape) {
   .language-options {
    height: 75vh;
    overflow: scroll;
  }
}
</style>
