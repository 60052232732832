<template>
  <img
    src="@/assets/images/CompeteHR-logo.webp"
    alt="Brandname Image"
  >
</template>

<script>
export default {
  name: 'BrandName'
}
</script>

<style lang="scss" scoped>
  img {
    width: 170px;
    // to prevent images from being blurry when the size is smaller than the original
    image-rendering: -moz-crisp-edges;         /* Firefox */
    image-rendering:   -o-crisp-edges;         /* Opera */
    image-rendering: -webkit-optimize-contrast;/* Webkit (non-standard naming) */
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;  /* IE (non-standard property) */
  }
  @media screen and (max-width: 350px){
    img {
    width: 150px;
  }
  }
</style>
