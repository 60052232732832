<template>
  <div class="home-container">
    <HomeMainSection
      :title="$t('General.ComplexHRProcesses', {}, { locale: $store.state.lang })"
      :subtitle="$t('General.MadeManageable', {}, { locale: $store.state.lang })"
      :btn-text="$t('General.ViewOurProducts', {}, { locale: $store.state.lang })"
      :primary-color="'#1DAAE1'"
      image-url="Main-Cropped.webp"
      @contact-btn-clicked="contactBtnClicked()"
    >
      <template #main-section>
        <p>
          {{ $t('General.HomePageTextPart1', {}, { locale: $store.state.lang }) }}
        </p>
        <p>
          {{ $t('General.HomePageTextPart2', {}, { locale: $store.state.lang }) }}
        </p>
      </template>
    </HomeMainSection>
    <OurSoftware
      :title="$t('General.OurSoftware', {}, { locale: $store.state.lang })"
      :primary-color="'#1DAAE1'"
      :background-color="'#FFFFFF'"
      image-url="Our-Software-Cropped.webp"
    >
      <template #main-section>
        <p>
          {{ $t('General.OurSoftwareTextPart1', {}, { locale: $store.state.lang }) }}
        </p>
        <p>
          {{ $t('General.OurSoftwareTextPart2', {}, { locale: $store.state.lang }) }}
        </p>
      </template>
    </OurSoftware>
    <StrongByService
      :service-items="serviceItems"
      title="ExtraFeatures"
    />
    <CustomersSection
      id="Customers"
      :primary-color="'#1DAAE1'"
      :title="$t('General.OurCustomers', {}, { locale: $store.state.lang })"
    />
    <Footer />
  </div>
</template>

<script>
import OurSoftware from '@/components/Sections/OurSoftware'
import StrongByService from '@/components/Sections/StrongByService'
import Footer from '@/components/Footer/Footer'
import CustomersSection from '@/components/Sections/CustomersSection'
import HomeMainSection from '@/components/Sections/HomeMainSection'
import { useMeta } from 'vue-meta'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

export default {
  name: 'Home',
  components: {
    OurSoftware, StrongByService, Footer, CustomersSection, HomeMainSection
  },
  setup () {
    const store = useStore()

    const { t } = useI18n({
      messages: {
        en: {
          title: 'Home',
          description: 'Business software should match your organisation\'s needs and requirements. When you use our task-specific software, your HR business process improves and speeds up.'
        },
        nl: {
          title: 'Home',
          description: 'Bedrijfssoftware dient aan te sluiten bij de wensen en behoeften van uw organisatie. Wanneer u gebruikt maakt van onze taak specifieke software verbetert en versnelt uw HR-bedrijfsproces.'
        }
      }
    })
    useMeta({
      title: t('title', {}, { locale: store.state.lang }),
      description: t('description', {}, { locale: store.state.lang })
    })
  },
  data () {
    return {
      serviceItems: [
        { id: 0, title: 'StrongByServiceTitleCard1', mainContent: 'StrongByServiceTextCard1', primaryColor: '#1DAAE1', linkText: '', linkUrl: '' },
        { id: 1, title: 'StrongByServiceTitleCard2', mainContent: 'StrongByServiceTextCard2', primaryColor: '#1DAAE1', linkText: '', linkUrl: '' },
        { id: 2, title: 'StrongByServiceTitleCard3', mainContent: 'StrongByServiceTextCard3', primaryColor: '#1DAAE1', linkText: '', linkUrl: '' },
        { id: 3, title: 'StrongByServiceTitleCard4', mainContent: 'StrongByServiceTextCard4', primaryColor: '#1DAAE1', linkText: '', linkUrl: '' }
      ]
    }
  },
  mounted () {
    this.$store.commit('updateActivePage', 'Home')
  },
  methods: {
    contactBtnClicked () {
      this.$router.push(`/${this.$store.state.lang}/contact`)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
