<template>
  <div>
    <h1
      :style="{color: primaryColor}"
    >
      {{ text }}<br>
    </h1>
    <span class="subtitle">
      {{ subtitle }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'Heading',
  props: {
    text: {
      type: String,
      default: 'Heading'
    },
    subtitle: {
      type: String,
      default: ''
    },
    primaryColor: {
      type: String,
      default: '#606060'
    }
  }
}
</script>

<style lang="scss" scoped>
h1 {
  margin-bottom: 0em;
  font-weight: 600;
}
.subtitle {
  color: #606060;
  font-size: 32px;
  font-weight: 600;
}

// From a width of 1066px show the ipad/mobile variant
@media screen and (max-width: 1066px) {
  h1 {
    font-size: 24px;
  }
  .subtitle {
    font-size: 24px;
  }
}
</style>
