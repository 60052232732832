import { createWebHistory, createRouter } from 'vue-router'
import Home from '@/views/Home'
import Pricing from '@/views/Pricing'
import About from '@/views/About'
// import Order from '@/views/Order'
// import WorkAtCompete from '@/views/WorkAtCompete'
import Contact from '@/views/Contact'
// import ProductSelector from '@/views/ProductSelector'
// import EmptyCard from '@/views/EmptyCard'
import PageNotFound from '@/views/PageNotFound'
import TermsAndConditions from '@/views/TermsAndConditions'
import Leave from '@/views/Leave'
import Time from '@/views/Time'
import Sick from '@/views/Sick'
import Timesick from '@/views/Timesick'
import { defaultLocale } from '../config/i18n'
import store from '../store/index'

const routes = [
  {
    path: '/',
    redirect: '/' + defaultLocale
  },
  {
    path: '/NotFound',
    name: 'PageNotFound',
    component: PageNotFound
  },
  {
    path: '/:locale',
    component: {
      template: '<router-view />'
    },
    beforeEnter: (to) => {
      if (to.params.locale !== 'nl' && to.params.locale !== 'en') { return { path: '/' + defaultLocale + '/NotFound' } }
      store.commit('updateLanguage', to.params.locale)
    },
    children: [
      {
        path: '',
        name: 'Home',
        component: Home
      },
      {
        path: 'pricing',
        name: 'Pricing',
        component: Pricing
      },
      {
        path: 'about',
        name: 'About',
        component: About
      },
      // {
      //   path: 'order/:productname',
      //   name: 'Order',
      //   component: Order
      // },
      // {
      //   path: 'workatcompete',
      //   name: 'WorkAtCompete',
      //   component: WorkAtCompete
      // },
      {
        path: 'contact',
        name: 'Contact',
        component: Contact
      },
      {
        path: 'product/leave',
        name: 'Leave',
        component: Leave
      },
      {
        path: 'product/time',
        name: 'Time',
        component: Time
      },
      {
        path: 'product/sick',
        name: 'Sick',
        component: Sick
      },
      {
        path: 'product/timesick',
        name: 'Timesick',
        component: Timesick
      },
      // {
      //   path: 'productselector',
      //   name: 'ProductSelector',
      //   component: ProductSelector
      // },
      {
        path: 'termsandconditions',
        name: 'TermsAndConditions',
        component: TermsAndConditions
      },
      // {
      //   path: 'emptycard',
      //   name: 'EmptyCard',
      //   component: EmptyCard
      // },
      {
        path: ':catchAll(.*)',
        name: 'PageNotFound',
        component: PageNotFound
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior () {
    return { left: 0, top: 0 }
  }
})

export default router
