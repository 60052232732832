<template>
  <div class="field">
    <label :for="fieldInfo.id">{{ $t(`Forms.${fieldInfo.label}`, {}, { locale: $store.state.lang }) }}</label>
    <div class="field-input">
      <input
        :id="fieldInfo.id"
        v-model="fieldValue"
        :type="fieldInfo.type"
        :name="fieldInfo.id"
        @blur="validate(fieldInfo.id)"
      >
      <info-popover
        :info-text="infoText"
        :error="inViolation"
        class="icon"
      />
    </div>
  </div>
</template>

<script>
import InfoPopover from './InfoPopover.vue'
export default {
  name: 'InputField',
  components: {
    InfoPopover
  },
  props: {
    fieldInfo: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      inViolation: false,
      infoText: this.fieldInfo.infoText
    }
  },
  computed: {
    fieldValue: {
      get () {
        return this.$store.state[this.fieldInfo.id]
      },
      set (value) {
        this.$store.commit(`update_${this.fieldInfo.id}`, value)
      }
    }
  },
  methods: {
    validate () {
      if (this.fieldInfo.required && (this.fieldValue === undefined || this.fieldValue === '')) {
        this.inViolation = true
        this.infoText = this.fieldInfo.requiredText
      } else if (this.fieldInfo.regex && !this.fieldInfo.regex.test(this.fieldInfo.cosmetics ? this.fieldInfo.cosmetics(this.fieldValue) : this.fieldValue)) {
        this.inViolation = true
        this.infoText = this.fieldInfo.regexText
      } else {
        this.inViolation = false
        this.infoText = this.fieldInfo.infoText
      }
      // emit the value of inviolation to check if the field is errorfree
      this.$store.commit('setMessageFormFieldInvalid', { inError: this.inViolation, fieldId: this.fieldInfo.id })
    }
  }
}
</script>

<style lang='scss' scoped>
.field {
  display: flex;
  flex-direction: column;
}

.field-input {
  display: flex;
  justify-content: space-between;
  padding: 0.5em 0 1em;
}
.field-input input {
  width: 100%;
  height: 30px;
  margin-right: 1em;
  border: none;
  -webkit-appearance: none;
  box-shadow: -2px 2px 2px #00000029;
  font-size: 16px;
  padding-left: 0.6em;
  color: #606060;
  font-family: inherit;
}
.field-input input:focus {
  outline: none;
}

.icon {
  position: relative;
  top: 0.2em;
  right: 0.5em;
}
@media screen and (max-width: 1066px) {
  .field-input input {
    margin-right: 0.3em;
  }
  .icon {
    right: 0.1em;
  }
}
</style>
