<template>
  <div class="container">
    <h1 class="middle">
      {{ $t('General.Contact', {}, { locale: $store.state.lang }) }}
    </h1>
    <div class="package">
      <p>
        {{ $t('General.WantToSeeProducts', {}, { locale: $store.state.lang }) }}
      </p>
    </div>
    <div>
      <h2 class="middle">
        {{ $t('General.ContactInfo', {}, { locale: $store.state.lang }) }}
      </h2>
      <div class="package">
        <p>Algerastraat 7</p>
        <p>3125 BS Schiedam</p>
      </div>
      <div class="package">
        <p><b>{{ $t('General.Sales', {}, { locale: $store.state.lang }) }}</b></p>
        <a href="tel:+31102382045">(+31) 10 238 20 45</a>
        <br>
        <a href="mailto: sales@compete.nl">sales@compete.nl</a>
      </div>
      <div
        class="package"
      >
        <p><b>{{ $t('General.Support', {}, { locale: $store.state.lang }) }}</b></p>
        {{ $t('General.SupportTextContactPage', {}, { locale: $store.state.lang }) }} <span class="here-link"><a
          :href="$t('General.SupportURL', {}, { locale: $store.state.lang })"
          target="_blank"
        >{{ $t('General.SupportTextContactPage2', {}, { locale: $store.state.lang }) }}</a></span>{{ $t('General.SupportTextContactPage3', {}, { locale: $store.state.lang }) }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContactComponent'

}
</script>

<style lang="scss" scoped>
  .container {
    margin: 2em 1em 2em 0;
     white-space: pre-line;
    h2 {
      margin-bottom: 0;
    }
    .middle {
      font-weight: 400;
    }
    .package {
        padding-bottom: 1em;
        .here-link {
          color: rgb(29, 170, 225);
          font-weight: bold;
          cursor: pointer;
        }
    }
    p {
      margin: 3px 0;
    }
    .link-btn {
      text-decoration: none;
      color: #1DAAE1;
    }
    a {
      text-decoration: none;
      color: inherit;
    }
  }
</style>
