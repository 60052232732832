<template>
  <div
    class="main-section"
    :style="{'background-color': backgroundColor}"
  >
    <div
      class="main-content"
    >
      <div class="heading">
        <Heading
          :text="title"
          :subtitle="subtitle"
          :primary-color="primaryColor"
        />
      </div>
      <div class="paragraph">
        <slot name="main-section" />
      </div>
      <div
        class="buttons-main"
      >
        <CompeteButton
          :text="btnText"
          :background-color="btnColor"
          :variation="'squareCorners'"
          :main-section="true"
          class="first-btn"
          @click="showProductsClicked"
        />
        <CompeteButton
          :text="$t('General.RequestDemo', {}, { locale: $store.state.lang })"
          :background-color="'#083342'"
          :variation="'squareCorners'"
          :main-section="true"
          class="second-btn"
          @btn-clicked="$emit('contact-btn-clicked')"
        />
      </div>
      <div class="review-stars">
        <Review />
      </div>
      <div class="main-image">
        <img
          :src="require(`@/assets/images/${imageUrl}`)"
          :alt="$t('General.PersonOnLaptopImg', {}, { locale: $store.state.lang })"
        >
      </div>
    </div>
  </div>
</template>

<script>
import Heading from '@/components/Heading/Heading'
import CompeteButton from '@/components/CompeteButton'
import Review from '@/components/Review'

export default {
  name: 'HomeMainSection',
  components: {
    Heading, CompeteButton, Review
  },
  props: {
    title: {
      type: String,
      default: 'Title'
    },
    subtitle: {
      type: String,
      default: ''
    },
    btnText: {
      type: String,
      default: 'Probeer gratis'
    },
    primaryColor: {
      type: String,
      default: '#606060'
    },
    btnColor: {
      type: String,
      default: '#1DAAE1'
    },
    backgroundColor: {
      type: String,
      default: '#F2F2F2'
    },
    imageUrl: {
      type: String,
      default: 'Main.webp'
    }

  },
  methods: {
    showProductsClicked () {
      if (this.$store.state.activeModule.length === 0) {
        if (window.innerWidth < 1066) {
          console.log('smaller')
          this.$store.commit('toggleHandler', 'showHamburger')
          this.$store.commit('toggleHandler', 'showMobileProductsMenu')
        } else {
          this.$store.commit('toggleHandler', 'showProductsMenu')
          this.$store.commit('toggleHandler', 'clickedShowProducts')
        }
        // close languages dropdown when navigation popup opens (this is the reason why this is arranged from the store)
        this.$store.commit('toggleHandler', 'closeLanguages')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .main-section {
    margin-bottom: 2em;
    .main-content {
      display: grid;
      grid-template-areas: '. heading main-image .'
                           '. paragraph main-image .'
                           '. buttons-main main-image .'
                           '. review-stars main-image .' ;
      grid-template-columns: 1.8fr 3.2fr 3.2fr 1.8fr;
      grid-template-rows: auto auto auto;
      .heading {
        grid-area: heading;
      }
      .paragraph {
        grid-area: paragraph;
      }
      .buttons-main {
        grid-area:buttons-main;
        padding-top:1em;
        padding-bottom: 2em;
      }
      .review-stars {
          grid-area: review-stars;
      }
      .main-image {
        grid-area: main-image;
        align-self: end; // vertical alignment
        justify-self: end;  // horizontal alignment
        padding-left:1em;
        width: calc(100% - 1em);
        height: calc(100%);
        img {
          display: block; // this is to remove the extra white space underneath an image
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: 55% 70%;
          image-rendering: -moz-crisp-edges;         /* Firefox */
          image-rendering:   -o-crisp-edges;         /* Opera */
          image-rendering: -webkit-optimize-contrast;/* Webkit (non-standard naming) */
          image-rendering: crisp-edges;
          -ms-interpolation-mode: nearest-neighbor;  /* IE (non-standard property) */
        }
      }
    }
    .first-btn {
      float:left;
    }
    .second-btn {
      float: left;
      margin-left: 0.5em;
    }
  }

    @media screen and (min-width: 2300px) {
      .main-section {
        min-height: 28vh;
        display: flex;
        justify-content: center;
        align-items: center;
        .main-content {
          grid-template-rows: auto auto auto;
          max-width: 2300px;
          .heading {
            align-self: end; // vertical alignment
          }
          .main-image, .paragraph {
            align-self: center; // vertical alignment
            justify-self: end;  // horizontal alignment
            img {
              display: block // this is to remove the extra white space underneath an image
            }
          }
        }
      }
    }
@media screen and (max-width: 1500px) {
  .main-section {
    .main-content {
      grid-template-columns: 0.8fr 4.2fr 4.2fr 0.8fr;
    }
  }
}

// From a width of 1066px show the ipad variant
@media screen and (max-width: 1066px) {
  .main-section {
    .main-content {
      grid-template-columns: 0.3fr 4.7fr 4.7fr 0.3fr;
        .buttons-main {
          .second-btn {
            margin-left: 0.4em;
        }
      }
    }
  }
}

// From a width of 740px show the mobile variant
@media screen and (max-width: 740px) {
  .main-section {
    .main-content {
      grid-template-columns: 0.3fr 9.4fr 0.3fr;
      grid-template-areas: '. main-image .'
                           '. heading .'
                           '. paragraph .'
                           '. buttons-main .'
                           '. review-stars .' ;
      .main-image {
        padding-left:0em;
        align-self: center; // vertical alignment
        justify-self: center;  // horizontal alignment
      }
      .heading {
        padding-top:0;
      }
      .paragraph {
        margin-top: -0.3em;
        padding-left:0;
      }
      .buttons-main {
        padding-top:0em;
        padding-bottom: 1em;
      }
    }
  }
}
</style>
